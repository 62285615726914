import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ProjectionTrackingScreen, WorkflowScreen } from 'modules/core/components'
import ForecastInitialScreen from './ForecastInitialScreen/ForecastInitialScreen'
import ForecastHome from './ForecastHome/ForecastHome'
import ForecastDetail from './ForecastDetail/ForecastDetail'
import GlobalForecastDetail from './GlobalForecastDetail/GlobalForecastDetail'
import MonitorWorkflow from './Workflow'

const Home = () => (
  <Switch>
    <Route exact path="/forecast/:periodId/" component={ForecastInitialScreen} />
    <Route exact path="/forecast/:periodId/:forecastId" component={ForecastHome} />
    <Route exact path="/forecast/:periodId/:forecastId/:conceptName" component={ForecastHome} />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId"
      component={GlobalForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId/:currency"
      component={GlobalForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId/:currency/:reportId"
      component={GlobalForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId/:currency/:reportId/:tableType"
      component={GlobalForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId/:currency/:reportId/:tableType/:base"
      component={GlobalForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName/:projectionId"
      component={ForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName/:projectionId/:step"
      component={ForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName/:projectionId/:step/:tab"
      component={ForecastDetail}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName/:step/actividad/:projectionId"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/:conceptName/:step/workflow/:projectionId"
      component={MonitorWorkflow}
    />
    <Route
      exact
      path="/forecast/:periodId/general/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route
      exact
      path="/forecast/:periodId/:forecastId/forecastglobal/:globalForecastId/actividad"
      component={ProjectionTrackingScreen}
    />
    <Route exact path="/forecast/:periodId/:forecastId/workflow" component={WorkflowScreen} />
  </Switch>
)

export default Home
