import { Col, Row, Spin } from 'antd'
import { Button, EmptyScreen } from 'modules/core/components'
import {
  ApplicationHistoricalDrawer,
  ApplicationManualCreation,
  CreateApplicationModal,
  GlobalBudgetSACDetail,
  GlobalBudgetSACList,
} from './components'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import planning from 'modules/planning'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import login from 'modules/login'

const GlobalBudgetSAC = ({
  fetchAdjustments,
  adjustmentsList,
  fetchApplicationDetail,
  applicationDetail,
  fetchAdjustmentsKpis,
  adjustmentsKpis,
  fetchAreasList,
  areaList,
  loggedUser: { pkid },
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showManualCreation, setShowManualCreation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDrowerHistorical, setShowDrowerHistorical] = useState(false)
  const [rowId, setRowId] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  const [detailLoading, setDetailLoading] = useState(false)
  const [isKpisLoading, setIsKpisLoading] = useState(false)
  const { t } = useTranslation()
  let { globalBudgetId, periodId } = useParams()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var applicationId = urlParams.get('applicationId')

  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/sac`,
    )
  }, [periodId, globalBudgetId])

  useEffect(() => {
    setIsLoading(true)
    fetchAdjustments({ projection: globalBudgetId, module: 'OVERALL_PLANNING' }).then(() =>
      setIsLoading(false),
    )
  }, [fetchAdjustments, globalBudgetId])

  useEffect(() => {
    setIsKpisLoading(true)
    fetchAdjustmentsKpis({ projection: globalBudgetId, module: 'OVERALL_PLANNING' }).then(() =>
      setIsKpisLoading(false),
    )
  }, [fetchAdjustmentsKpis, globalBudgetId])

  useEffect(() => {
    if (rowId) {
      setDetailLoading(true)
      fetchApplicationDetail({ adjustment_id: rowId }).then(() => setDetailLoading(false))
    }
  }, [fetchApplicationDetail, rowId])

  useEffect(() => {
    fetchAreasList({ user_id: pkid })
  }, [fetchAreasList, pkid])

  useEffect(() => {
    if (applicationId) {
      setRowId(applicationId)
      setShowDetail(true)
    }
  }, [applicationId])

  const refetch = () => {
    setIsLoading(true)
    setIsKpisLoading(true)
    Promise.all([
      fetchAdjustments({ projection: globalBudgetId, module: 'OVERALL_PLANNING' }),
      fetchAdjustmentsKpis({ projection: globalBudgetId, module: 'OVERALL_PLANNING' }),
    ]).then(() => {
      setIsLoading(false)
      setIsKpisLoading(false)
    })
  }

  return (
    <>
      {!showManualCreation && (
        <Spin spinning={isLoading}>
          <Row gutter={[24, 24]}>
            {adjustmentsList.length === 0 && (
              <Col span={24}>
                <EmptyScreen
                  image={emptyStateImage}
                  description={t('PLANNING_SAC_EMPTY_SCREEN_DESCRIPTION')}
                  footer={
                    <Button.Primary
                      disabled={areaList.length === 0}
                      title="ACTION_CREATE_NEW"
                      onClick={() => setShowCreateModal(true)}
                      tooltipTitle={
                        areaList.length === 0
                          ? t('PLANNING_SAC_INFORMATIVE_CREATE_AREAS_TOOLTIP_TITLE')
                          : ''
                      }
                    />
                  }
                />
              </Col>
            )}
            {adjustmentsList.length > 0 && (
              <>
                {!showDetail && (
                  <GlobalBudgetSACList
                    isKpisLoading={isKpisLoading}
                    adjustmentsKpis={adjustmentsKpis}
                    adjustmentsList={adjustmentsList}
                    onClickDetail={(id) => {
                      setShowDetail(true)
                      setRowId(id)
                    }}
                    onClickHistorical={(id) => {
                      setShowDrowerHistorical(true)
                      setRowId(id)
                    }}
                    onClickCreate={() => setShowCreateModal(true)}
                    onRefetch={refetch}
                    areaList={areaList}
                  />
                )}
                {showDetail && (
                  <GlobalBudgetSACDetail
                    applicationDetail={applicationDetail}
                    detailLoading={detailLoading}
                    onReturn={() => {
                      setShowDetail(false)
                      setRowId(null)
                    }}
                  />
                )}
              </>
            )}
          </Row>
          <ApplicationHistoricalDrawer
            visible={showDrowerHistorical}
            onClose={() => setShowDrowerHistorical(false)}
            rowId={rowId}
          />
        </Spin>
      )}
      {showManualCreation && (
        <ApplicationManualCreation
          onRefetch={refetch}
          onReturn={() => setShowManualCreation(false)}
        />
      )}
      <CreateApplicationModal
        visible={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onRedirect={() => setShowManualCreation(true)}
        onRefetch={refetch}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  adjustmentsList: planning.selectors.getAdjustmentsList(state),
  applicationDetail: planning.selectors.getApplicationDetail(state),
  adjustmentsKpis: planning.selectors.getAdjustmentsKpis(state),
  areaList: configuration.selectors.getAreaList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchAdjustments: planning.actions.fetchAdjustments,
  fetchApplicationDetail: planning.actions.fetchApplicationDetail,
  fetchAdjustmentsKpis: planning.actions.fetchAdjustmentsKpis,
  fetchAreasList: configuration.actions.fetchAreasList,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBudgetSAC)
