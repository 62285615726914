import axios from 'axios'
import { tokenutils } from './modules/login/actions'
import _ from 'lodash'

export const API_ROOT = process.env.REACT_APP_API_ROOT || 'https://stg.getplika.com/api/v1'

const client = axios.create({
  baseURL: API_ROOT,
})

const clientRefresh = axios.create({
  baseURL: API_ROOT,
})

const refreshToken = async () => {
  try {
    const refresh_token = tokenutils.getRefreshToken()
    const response = await clientRefresh.post(`/auth/jwt/refresh/`, { refresh_token })
    const session = response.data

    if (!session.access_token) {
      tokenutils.removeToken()
    }
    tokenutils.saveTokens(session)

    return session.access
  } catch (error) {
    console.log('error', error)
    tokenutils.removeToken()
  }
}

client.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem('access_token') || null
    if (token) {
      config.headers = {
        ...config.headers,
        'Authorization': `Bearer ${token}`,
        'Accept-Language': 'es',
      }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// TODO:
// * Improve this interceptor for logout token expired.
// * Use redux flow and clean store not only localStorage.
// * Use react-router for redirect, maybe implement HOC.
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config
    const response = error?.response

    if (response?.status === 401) {
      config.sent = true
      const token = await refreshToken()

      if (!_.isEmpty(token)) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        }
        return client(config)
      }
    }

    if (error.response.status === 403) {
      return Promise.reject({
        ...error,
        response: {
          ...error.response,
          data: 'No tiene los permisos para realizar la acción.',
        },
      })
    }
    return Promise.reject(error)
  },
)

const responseBody = (res) => res.data.results
const requests = {
  delete: (url) => client.delete(url).then(responseBody),
  get: (url) => client.get(url).then(responseBody),
  put: (url, body) => client.put(url, body).then(responseBody),
  patch: (url, body) => client.patch(url, body).then(responseBody),
  post: (url, body) => client.post(url, body).then(responseBody),
}

export { client }
export default requests
