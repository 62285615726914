import { DownOutlined } from '@ant-design/icons'
import { message, notification } from 'antd'
import i18n from 'i18next'
import _, { isEmpty } from 'lodash'
import { generateMessageError } from 'modules/core/utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from '../../../configuration'
import login from '../../../login'
import societies from '../../../societies'
import SOCIETIES from '../../constants/societies'
import { Button, DropdownMenu } from '../index'
import { CustomBottomModal } from './components'

const CustomBottomAction = ({
  extraButtons,
  fetchFunctionsButtonsTenant,
  functionsButtonsTenant,
  FunctionsButtonsExecute,
  fetchDimensionValues,
  loggedUser: { dataPermissions, roles },
  disabled = false,
}) => {
  const { t } = useTranslation()

  const [loadingAction, setLoadingAction] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentAction, setCurrentAction] = useState()
  const [isLoading, setILoading] = useState(false)
  const [dimensionValues, setDimensionValues] = useState([])

  const isAdmin = roles.find((el) => el.code === 'adm' && el.name === 'Admin')

  const getFunctionsButtons = (values) => {
    if (!isAdmin) {
      return values.filter((el) => !el.metadata?.filter_by_admin)
    } else {
      return values
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoadingAction(true)

      try {
        const res = await fetchFunctionsButtonsTenant(extraButtons)
        const functions = res.payload.data.functions

        const dimensionIds = [
          ...new Set(
            functions
              .filter((it) => it?.metadata?.filter_by_dimension != null)
              .map((it) => it.metadata.filter_by_dimension),
          ),
        ]
        // 3. Verificar si hay IDs de dimensión, si no, terminar la función.
        if (dimensionIds.length === 0) {
          setLoadingAction(false)
          return
        }

        const dimensionPromises = dimensionIds.map((id) =>
          fetchDimensionValues({ id_dimension: id }).then((res) => ({
            id,
            values: res.payload.data,
          })),
        )

        const dimensionResults = await Promise.all(dimensionPromises)

        setDimensionValues(dimensionResults)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoadingAction(false)
      }
    }

    fetchData()
  }, [fetchFunctionsButtonsTenant])

  const actions = getFunctionsButtons(functionsButtonsTenant.values)

  const onClick = (url) => {
    window.open(url, '_blank')
  }

  const onTitle = (method) => {
    if (method === 'spreadsheets') {
      return i18n.t('FEEDBACK_SPREADSHEET_TEXT')
    }
    return i18n.t('FEEDBACK_DEFAULT_TEXT')
  }

  const isValidURL = (value) => {
    if (!_.isString(value)) {
      return false
    }

    try {
      new URL(value)
      return true
    } catch (err) {
      return false
    }
  }

  const handleClick = (customButton, metadata = {}) => {
    const typeAction = customButton?.action || null
    if (customButton.is_upload_file) {
      setCurrentAction(customButton)
      setShowModal(true)
    } else {
      const key = `open${Date.now()}`
      setILoading(true)
      message.loading({
        key,
        content: i18n.t('FEEDBACK_LOAD_TEXT'),
        duration: 0,
      })
      const responseType = typeAction === SOCIETIES.SOCIETIES_KEYS.ACTION_DOWNLOAD ? 'blob' : null
      FunctionsButtonsExecute(
        customButton?.function_call.slice(6),
        [],
        {
          function_id: customButton?.id,
          ...extraButtons,
          ...(!isEmpty(metadata) ? { metadata: JSON.stringify(metadata) } : {}),
        },
        {},
        responseType,
      )
        .then((res) => {
          const data = res.payload.data
          setILoading(false)
          if (data?.url && isValidURL(data?.url)) {
            message.destroy(key)
            notification.info({
              message: i18n.t('FEEDBACK_FUNCTION_ACTION_SUCCESS'),
              description: (
                <>
                  <span>{onTitle(data?.method)}</span>{' '}
                  <span
                    onClick={() => onClick(data.url)}
                    style={{ color: '#0047ba', cursor: 'pointer' }}
                  >
                    {i18n.t('DASHBOARD_WHATSAPP_CONTACT_LINK')}
                  </span>
                </>
              ),
              duration: 0,
            })
          } else {
            message.destroy(key)
            message.success(i18n.t('FEEDBACK_FUNCTION_ACTION_SUCCESS'), 8)
          }
        })
        .catch((error) => {
          setILoading(false)
          message.destroy(key)
          notification.error({
            message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error, 'FEEDBACK_LOAD_DATA_ERROR'),
            duration: 0,
          })
        })
    }
  }

  const buildMenu = (action) => {
    if (action?.metadata?.filter_by_dimension) {
      const itemDataDimension = dataPermissions.find(
        (it) => it.table_id === action.metadata.filter_by_dimension,
      )

      if (itemDataDimension && !itemDataDimension.all_selected) {
        const valueMap = new Map(dimensionValues.map((it) => [it.id, it.values || []]))

        const subMenu = itemDataDimension.dimension_values
          .map((it) => {
            const values = valueMap.get(itemDataDimension.table_id) || []
            const value = values.find((v) => v.id.toString() === it.dim_value_id.toString())

            if (value) {
              const { source_data, ...propsDimension } = value

              return {
                title: value.name_destination,
                key: value.id,
                onClick: () => handleClick(action, propsDimension),
                disabled: isLoading,
              }
            }

            return null
          })
          .filter(Boolean)

        return subMenu.length ? subMenu : undefined
      }
    }

    return undefined
  }

  return (
    <>
      {functionsButtonsTenant.values.length > 0 && (
        <DropdownMenu
          title={t('ACTION_MORE')}
          placement="bottomRight"
          disabled={disabled}
          menu={actions.map((it) => {
            const subMenu = buildMenu(it)
            return {
              title: it.name_button,
              key: it.id,
              disabled: isLoading,
              ...(isEmpty(subMenu) ? { onClick: () => handleClick(it) } : {}),
              ...(!isEmpty(subMenu) ? { submenu: subMenu } : {}),
            }
          })}
        >
          <Button.DefaultWhitRightIcon
            icon={<DownOutlined />}
            title="ACTION_MORE"
            loading={loadingAction}
            disabled={!disabled ? isLoading : disabled}
            tooltipTitle={isLoading ? t('PROCESS_INFORMATIVE_TOOLTIP_TITLE') : ''}
          />
        </DropdownMenu>
      )}
      <CustomBottomModal
        showModal={showModal}
        setShowModal={setShowModal}
        currentAction={currentAction}
        setCurrentAction={setCurrentAction}
        handleExecute={FunctionsButtonsExecute}
        extraButtons={extraButtons}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  functionsButtonsTenant: societies.selectors.getFunctionsButtonsTenant(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchFunctionsButtonsTenant: societies.actions.fetchFunctionsButtonsTenant,
  FunctionsButtonsExecute: societies.actions.FunctionsButtonsExecute,
  fetchDimensionValues: configuration.actions.fetchAllDimensionsValues,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomBottomAction)
