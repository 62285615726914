import { Tabs, Tooltip } from 'antd'
import { GlobalBudgetDetail, GlobalBudgetSAC } from './components'
import { SiderLayout } from 'modules/core/layouts'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { PeriodNavigator } from 'modules/core/components'
import { PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import planning from 'modules/planning'

const GlobalBudgetDetailHome = ({ fetchGlobalBudgetDetail, globalBudgetDetail }) => {
  let { periodId, globalBudgetId, type } = useParams()
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [tabKey, setTabKey] = useState(type || 'budget')
  const { t } = useTranslation()

  useEffect(() => {
    setIsScreenLoading(true)
    fetchGlobalBudgetDetail(globalBudgetId).then(() => setIsScreenLoading(false))
    return () => setIsScreenLoading(false)
  }, [fetchGlobalBudgetDetail, globalBudgetId])

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_PLANNING'),
          url: `/planificacion/${periodId}/${PROJECTION.GLOBAL_BUDGET}`,
        },
        { name: globalBudgetDetail?.name },
      ]}
    />
  )

  const handleClick = (key) => {
    setTabKey(key)
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${key}`,
    )
  }

  return (
    <SiderLayout>
      {renderHeader()}
      <Tabs
        size="small"
        defaultActiveKey={tabKey}
        onChange={handleClick}
        style={{ paddingTop: 24 }}
      >
        <Tabs.TabPane tab={t('LABEL_BUDGET')} key="budget">
          <GlobalBudgetDetail
            isScreenLoading={isScreenLoading}
            globalBudgetDetail={globalBudgetDetail}
            onRefresh={() => fetchGlobalBudgetDetail(globalBudgetId)}
            tabKey={tabKey}
            setTabKey={setTabKey}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Tooltip
              title={
                globalBudgetDetail.status !== 'approved'
                  ? t('PLANNING_SAC_INFORMATIVE_APPROVE_BUDGET_TOOLTIP_TITLE')
                  : ''
              }
            >
              SAC
            </Tooltip>
          }
          disabled={globalBudgetDetail.status !== 'approved'}
          key="sac"
        >
          <GlobalBudgetSAC />
        </Tabs.TabPane>
      </Tabs>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  globalBudgetDetail: planning.selectors.getGlobalBudgetDetail(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetDetail: planning.actions.fetchGlobalBudgetDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBudgetDetailHome)
