import { notification } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { tokenutils } from '../../modules/login/actions'
import { sessionExpired } from './actions'

const SessionChecker = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const checkSession = () => {
      const token = tokenutils.getRefreshToken()
      if (token && !tokenutils.checkTokenFreshness(token, 360)) {
        dispatch(sessionExpired())
        notification.warning({
          message: 'Sesión caducada',
          description: 'Tu sesión ha caducado. Por favor, inicia sesión nuevamente.',
        })
        history.push('/login')
      }
    }

    const interval = setInterval(checkSession, 300000)

    return () => clearInterval(interval)
  }, [dispatch, history])

  return null
}

export default SessionChecker
