import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SiderLayout } from 'modules/core/layouts'
import { Col, Row, Spin } from 'antd'
import { PeriodNavigator, ProjectionSteps } from 'modules/core/components'
import { useParams } from 'react-router-dom'
import { generateIndexPhase, isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, PROJECTION, ROLES } from 'modules/core/constants'
import { BaseBudget } from './components'
import { isStepClosing } from 'modules/core/components/ProjectionComponents/utils'
import { usePagination } from 'modules/core/customHooks'
import planning from 'modules/planning'
import _ from 'lodash'

import './PlanningDetail.scss'

const {
  PLANNING__SALES__BASE__ABM,
  PLANNING__SALES__BASE__VIEW,
  PLANNING__SALES__TOP_DOWN__ABM,
  PLANNING__SALES__TOP_DOWN__VIEW,
  PLANNING__SALES__BOTTOM_UP__ABM,
  PLANNING__SALES__BOTTOM_UP__VIEW,
  PLANNING__SALES__CLOSING__ABM,
  PLANNING__SALES__CLOSING__VIEW,
  PLANNING__EXPENSES__BASE__ABM,
  PLANNING__EXPENSES__BASE__VIEW,
  PLANNING__EXPENSES__TOP_DOWN__ABM,
  PLANNING__EXPENSES__TOP_DOWN__VIEW,
  PLANNING__EXPENSES__BOTTOM_UP__ABM,
  PLANNING__EXPENSES__BOTTOM_UP__VIEW,
  PLANNING__EXPENSES__CLOSING__ABM,
  PLANNING__EXPENSES__CLOSING__VIEW,
  PLANNING__COSTS__BASE__ABM,
  PLANNING__COSTS__BASE__VIEW,
  PLANNING__COSTS__TOP_DOWN__ABM,
  PLANNING__COSTS__TOP_DOWN__VIEW,
  PLANNING__COSTS__BOTTOM_UP__ABM,
  PLANNING__COSTS__BOTTOM_UP__VIEW,
  PLANNING__COSTS__CLOSING__ABM,
  PLANNING__COSTS__CLOSING__VIEW,
  PLANNING__HUMAN_RESOURCES__BASE__ABM,
  PLANNING__HUMAN_RESOURCES__BASE__VIEW,
  PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
  PLANNING__HUMAN_RESOURCES__TOP_DOWN__VIEW,
  PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  PLANNING__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
  PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
  PLANNING__HUMAN_RESOURCES__CLOSING__VIEW,
  PLANNING__OTHER_5__BASE__ABM,
  PLANNING__OTHER_5__BASE__VIEW,
  PLANNING__OTHER_5__TOP_DOWN__ABM,
  PLANNING__OTHER_5__TOP_DOWN__VIEW,
  PLANNING__OTHER_5__BOTTOM_UP__ABM,
  PLANNING__OTHER_5__BOTTOM_UP__VIEW,
  PLANNING__OTHER_5__CLOSING__ABM,
  PLANNING__OTHER_5__CLOSING__VIEW,
  PLANNING__OTHER_6__BASE__ABM,
  PLANNING__OTHER_6__BASE__VIEW,
  PLANNING__OTHER_6__TOP_DOWN__ABM,
  PLANNING__OTHER_6__TOP_DOWN__VIEW,
  PLANNING__OTHER_6__BOTTOM_UP__ABM,
  PLANNING__OTHER_6__BOTTOM_UP__VIEW,
  PLANNING__OTHER_6__CLOSING__ABM,
  PLANNING__OTHER_6__CLOSING__VIEW,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const PLANNING__CONCEPT = {
  VIEW: {
    [CONCEPTS.IDS.SALES_ID]: {
      [PROJECTION.BASE]: [PLANNING__SALES__BASE__ABM, PLANNING__SALES__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [PLANNING__SALES__TOP_DOWN__ABM, PLANNING__SALES__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [PLANNING__SALES__BOTTOM_UP__ABM, PLANNING__SALES__BOTTOM_UP__VIEW],
      [PROJECTION.CLOSING]: [PLANNING__SALES__CLOSING__ABM, PLANNING__SALES__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.EXPENSES_ID]: {
      [PROJECTION.BASE]: [PLANNING__EXPENSES__BASE__ABM, PLANNING__EXPENSES__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [PLANNING__EXPENSES__TOP_DOWN__ABM, PLANNING__EXPENSES__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        PLANNING__EXPENSES__BOTTOM_UP__ABM,
        PLANNING__EXPENSES__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [PLANNING__EXPENSES__CLOSING__ABM, PLANNING__EXPENSES__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.COSTS_ID]: {
      [PROJECTION.BASE]: [PLANNING__COSTS__BASE__ABM, PLANNING__COSTS__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [PLANNING__COSTS__TOP_DOWN__ABM, PLANNING__COSTS__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [PLANNING__COSTS__BOTTOM_UP__ABM, PLANNING__COSTS__BOTTOM_UP__VIEW],
      [PROJECTION.CLOSING]: [PLANNING__COSTS__CLOSING__ABM, PLANNING__COSTS__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
      [PROJECTION.BASE]: [
        PLANNING__HUMAN_RESOURCES__BASE__ABM,
        PLANNING__HUMAN_RESOURCES__BASE__VIEW,
      ],
      [PROJECTION.TOPDOWN]: [
        PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
        PLANNING__HUMAN_RESOURCES__TOP_DOWN__VIEW,
      ],
      [PROJECTION.BOTTOMUP]: [
        PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
        PLANNING__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [
        PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
        PLANNING__HUMAN_RESOURCES__CLOSING__VIEW,
      ],
    },
    [CONCEPTS.IDS.OTHER_5_ID]: {
      [PROJECTION.BASE]: [PLANNING__OTHER_5__BASE__ABM, PLANNING__OTHER_5__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [PLANNING__OTHER_5__TOP_DOWN__ABM, PLANNING__OTHER_5__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        PLANNING__OTHER_5__BOTTOM_UP__ABM,
        PLANNING__OTHER_5__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [PLANNING__OTHER_5__CLOSING__ABM, PLANNING__OTHER_5__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.OTHER_6_ID]: {
      [PROJECTION.BASE]: [PLANNING__OTHER_6__BASE__ABM, PLANNING__OTHER_6__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [PLANNING__OTHER_6__TOP_DOWN__ABM, PLANNING__OTHER_6__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        PLANNING__OTHER_6__BOTTOM_UP__ABM,
        PLANNING__OTHER_6__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [PLANNING__OTHER_6__CLOSING__ABM, PLANNING__OTHER_6__CLOSING__VIEW],
    },
  },
}

const PlanningDetail = ({ dataPhaseBudget, budgetInstanceDetail, fetchBudgetInstanceDetail }) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [current, setCurrent] = useState(0)
  const [showTableqp, setShowTableqp] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  let { periodId, budgetId, step } = useParams()
  const { t } = useTranslation()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var phase = urlParams.get('phase')

  const verifyPermission = (concept_id, el) => {
    const permission = [
      PROJECTION.GLOBAL_BUDGET.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(concept_id.toString())) {
      return isUserAllowed(PLANNING__CONCEPT.VIEW[concept_id][el.key.toUpperCase()])
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const filterDataPhaseBudgetByRole = dataPhaseBudget.filter((el) =>
    !_.isEmpty(budgetInstanceDetail)
      ? verifyPermission(budgetInstanceDetail?.concept_id, el)
      : true,
  )

  const index = generateIndexPhase(budgetInstanceDetail, filterDataPhaseBudgetByRole, phase || step)

  useEffect(() => {
    setIsScreenLoading(true)
    fetchBudgetInstanceDetail(budgetId).then(() => setIsScreenLoading(false))
  }, [fetchBudgetInstanceDetail, budgetId])

  useEffect(() => {
    setCurrent(index)
  }, [index])

  const aux =
    phase || step ? dataPhaseBudget.findIndex((el) => el.key === budgetInstanceDetail?.step) : null

  const disablePhases = dataPhaseBudget.slice(aux ? aux + 1 : index + 1, dataPhaseBudget.length)
  const status_budget = isStepClosing(dataPhaseBudget[current].key)
    ? 'finish'
    : dataPhaseBudget[current + 1].key

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_PLANNING'),
          url: `/planificacion/${periodId}/${budgetInstanceDetail?.concept_name}`,
        },
        { name: budgetInstanceDetail?.name || '' },
      ]}
    />
  )

  const handleClick = (key) => {
    setCurrent(key)
    setShowTableqp(false)
    const phase = dataPhaseBudget[key].key
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/${budgetInstanceDetail?.concept_name}/${budgetInstanceDetail?.id}/${phase}`,
    )
  }

  return (
    <SiderLayout extraKey={`-${dataPhaseBudget[current].key}`}>
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && renderHeader()}
        {!isScreenLoading && (
          <Row gutter={[4, 12]}>
            <Col span={24}>
              <ProjectionSteps
                current={current}
                setCurrent={(val) => {
                  handleClick(val)
                }}
                dataProjectionPhase={filterDataPhaseBudgetByRole}
                disablePhases={disablePhases}
              />
            </Col>
            <Col span={24}>
              <BaseBudget
                budgetInstanceDetail={budgetInstanceDetail}
                dataPhase={dataPhaseBudget[current]}
                onRefetch={() => fetchBudgetInstanceDetail(budgetId)}
                status_budget={status_budget}
                setTablePagination={setTablePagination}
                tablePagination={tablePagination}
                showTableqp={showTableqp}
                setShowTableqp={setShowTableqp}
              />
            </Col>
          </Row>
        )}
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  budgetInstanceDetail: planning.selectors.getBudgetInstanceDetail(state),
  dataPhaseBudget: planning.selectors.getDataPhaseBudget(state),
})

const mapDispatchToProps = {
  fetchBudgetInstanceDetail: planning.actions.fetchBudgetInstanceDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningDetail)
