import { Select, Space } from 'antd'
import { TAB_KEYS } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'

const TableTypeSelect = ({
  tableKey,
  setTableKey,
  defaultPhase,
  dataPhaseBudget,
  setDefaultPhase,
}) => {
  const { t } = useTranslation()
  return (
    <Space direction="horizontal">
      <Select
        defaultValue={tableKey}
        key={tableKey}
        style={{ width: 200 }}
        options={generateSelectOptions({
          options: [
            { id: TAB_KEYS.TAB_KEY_EVOLUTION, name: t('FIELD_EVOLUTION') },
            { id: TAB_KEYS.TAB_KEY_COMPARATION, name: t('FIELD_COMPARISONS') },
          ],
        })}
        onChange={setTableKey}
      />
      {tableKey === TAB_KEYS.TAB_KEY_EVOLUTION && (
        <Select
          defaultValue={defaultPhase}
          style={{ width: 200 }}
          options={generateSelectOptions({
            options: dataPhaseBudget.map((phase) => {
              return { id: phase.key, name: t(phase.title) }
            }),
          })}
          onChange={setDefaultPhase}
        />
      )}
    </Space>
  )
}

export default TableTypeSelect
