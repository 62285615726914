import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Spin, Tabs } from 'antd'
import { connect } from 'react-redux'
import { INTEGRATIONS, TABLES, TAB_KEYS } from 'modules/core/constants'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ComparisonTable,
  EvolutionTable,
  TableTypeSelect,
  GlobalProjectionLineChart,
  DownloadModal,
  SelectType,
} from 'modules/core/components'
import { setPhaseAvailable } from 'modules/core/utils'
import reports from 'modules/reports'
import planning from 'modules/planning'
import login from 'modules/login'
import moment from 'moment'
import ComparisonPivotTable from './components/ComparisonPivotTable'
import EvolutionPivotTable from './components/EvolutionPivotTable'
import { SelectReportLine } from 'modules/reports/containers/ReportDetailHome/components/ReportDetail/components/ReportChart/components/ChartTitle/components'
import _ from 'lodash'

const TabsReports = ({
  tableId,
  setTableId,
  analysisList,
  loading,
  dataPhaseBudget,
  stepsBudgetInstance,
  dataPeriod,
  globalBudgetName,
  selectedDates,
  filters,
  tableLoading,
  allDimensionsList,
  defaultPhase,
  setDefaultPhase,
  auxCurrencyId,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  currencyData,
  showProgressBar,
  setShowProgressBar,
  isGlobalBudgetApproved,
  fetchGlobalBudgetComparisonTable,
  globalBudgetComparisonTable,
  fetchGlobalBudgetEvolutionTable,
  globalBudgetEvolutionTable,
  fetchGlobalBudgetChart,
  globalBudgetChart,
  fetchEvolutionTableOpenRow,
  fetchComparisonTableOpenRow,
  downloadGlobalBudgeEvolutiontXLS,
  downloadGlobalBudgeComparisontXLS,
  loggedUser: { folder_id },
  fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget,
  globalBudgetChartDefault,
  setReportLineSelectedPlanning,
  tabKey,
}) => {
  // Feature flag
  const enabledEvolution = useFeatureIsOn('feature-react-pivot-evolution')
  const enabledComparative = useFeatureIsOn('feature-react-pivot-comparative')

  let { periodId, globalBudgetId, tableType, base } = useParams()
  const [periodTableSettings, setPeriodTableSettings] = useState(TABLES.DEFAULT_SETTINGS)
  const [isEvolutionLoading, setIsEvolutionLoading] = useState(false)
  const [isComparisonLoading, setIsComparisonLoading] = useState(false)
  const [tableKey, setTableKey] = useState(tableType || TAB_KEYS.TAB_KEY_EVOLUTION)
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [SAC, setSAC] = useState(false)
  const [localReferenceBase, setLocalReferenceBase] = useState({
    value: base ? base.toUpperCase() : 'BASE',
    id: null,
  })
  const [localReferenceComparisonBase, setLocalReferenceComparisonBase] = useState([])
  const [optionsSelected, setOptionsSelected] = useState([])
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [showTableqp, setShowTableqp] = useState(false)
  // const [reportLineSelected,setReportLineSelected] = useState(null);
  const { t } = useTranslation()

  let comparativeOptions = _.compact(
    setPhaseAvailable(stepsBudgetInstance, dataPhaseBudget).map(
      (el) => el.key.toUpperCase() !== localReferenceBase.value && el.key,
    ),
  )

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var comparison = urlParams.get('comparison')
  var table = urlParams.get('table')

  const dataHash =
    optionsSelected.length > 0
      ? JSON.stringify(optionsSelected)
      : JSON.stringify(comparativeOptions)

  let hashComparison = Buffer.from(dataHash).toString('base64')

  useEffect(() => {
    if (tabKey !== 'budget') {
      setTableKey(TAB_KEYS.TAB_KEY_EVOLUTION)
    }
  }, [tabKey])

  useEffect(() => {
    if (comparison) {
      const json = Buffer.from(comparison, 'base64').toString()
      const dataComparison = JSON.parse(json)
      setOptionsSelected(dataComparison)
    }
  }, [comparison])

  useEffect(() => {
    if (
      tabKey === 'budget' &&
      tableKey === TAB_KEYS.TAB_KEY_COMPARATION &&
      (optionsSelected.length > 0 || comparativeOptions.length > 0)
    ) {
      window.history.replaceState(
        null,
        '',
        `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${tabKey}/${
          currencyData.id
        }/${tableId}/${tableKey}/${defaultPhase}?${
          comparativeOptions.length > 0 ? `comparison=${hashComparison}` : ''
        }${table ? `&table=${table}` : ''}`,
      )
    }
  }, [
    currencyData,
    tabKey,
    tableId,
    tableKey,
    defaultPhase,
    periodId,
    globalBudgetId,
    optionsSelected,
    comparativeOptions,
    hashComparison,
    table,
  ])

  useEffect(() => {
    if (tableId) {
      setIsChartLoading(true)
      fetchGlobalBudgetChart({
        period_id: periodId,
        overall_budget_id: globalBudgetId,
        templated_id: tableId,
        step: defaultPhase,
        ...selectedDates,
        ...filters,
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }).then(() => setIsChartLoading(false))
    }
  }, [
    fetchGlobalBudgetChart,
    periodId,
    globalBudgetId,
    selectedDates,
    filters,
    auxCurrencyId,
    tableId,
    defaultPhase,
  ])

  useEffect(() => {
    if (tableKey !== TAB_KEYS.TAB_KEY_EVOLUTION) return

    if (tableId) {
      setIsEvolutionLoading(true)
      setShowProgressBar(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledEvolution) {
        fetchDataEvolutionByOverallBudget(tableId, parseInt(globalBudgetId), defaultPhase, {
          sac: SAC,
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => {
          setIsEvolutionLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      } else {
        fetchGlobalBudgetEvolutionTable(tableId, parseInt(globalBudgetId), defaultPhase, {
          ...selectedDates,
          ...filters,
        }).then(() => {
          setIsEvolutionLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      }
    }
  }, [tableId, globalBudgetId, defaultPhase, selectedDates, filters, auxCurrencyId, tableKey, SAC])

  useEffect(() => {
    if (tableKey !== TAB_KEYS.TAB_KEY_COMPARATION) return

    if (tableId) {
      setIsComparisonLoading(true)
      setShowProgressBar(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledComparative) {
        fetchDataComparativeByOverallBudget(tableId, parseInt(globalBudgetId), {
          comparative_steps:
            localReferenceComparisonBase.length === 0
              ? [...comparativeOptions, localReferenceBase.value.toLowerCase()]
              : [...localReferenceComparisonBase, localReferenceBase.value.toLowerCase()],
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => {
          setIsComparisonLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      } else {
        fetchGlobalBudgetComparisonTable(tableId, parseInt(globalBudgetId), {
          ...selectedDates,
          ...filters,
        }).then(() => {
          setIsComparisonLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      }
    }
  }, [
    tableId,
    globalBudgetId,
    selectedDates,
    filters,
    auxCurrencyId,
    tableKey,
    localReferenceComparisonBase,
    localReferenceBase,
  ])

  useEffect(() => {
    setPeriodTableColumns(globalBudgetEvolutionTable?.columns)
  }, [globalBudgetEvolutionTable?.columns])

  const download = {
    evolution: () =>
      downloadGlobalBudgeEvolutiontXLS(
        t('PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME', {
          name: globalBudgetName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalBudgetId,
        defaultPhase,
        { ...selectedDates, ...filters },
      ),
    comparison: () =>
      downloadGlobalBudgeComparisontXLS(
        t('PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME', {
          name: globalBudgetName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalBudgetId,
        { ...selectedDates, ...filters },
      ),
  }

  const getEvolutionTable = () => {
    if (enabledEvolution) {
      return (
        <EvolutionPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          setShowModal={setShowModal}
          globalProjectionId={globalBudgetId}
          allDimensionsList={allDimensionsList}
          isEvolutionLoading={isEvolutionLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataEvolutionByOverallBudget(tableId, parseInt(globalBudgetId), defaultPhase, {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          showProgressBar={showProgressBar}
          currencyData={currencyData}
          setSAC={setSAC}
          defaultPhase={defaultPhase}
          isGlobalBudgetApproved={isGlobalBudgetApproved}
          tabKey={tabKey}
          tableKey={tableKey}
          showTableqp={showTableqp}
          setShowTableqp={setShowTableqp}
        />
      )
    }
    return (
      <EvolutionTable
        reportId={tableId}
        rows={globalBudgetEvolutionTable.rows}
        columns={periodTableColumns}
        loading={isEvolutionLoading || tableLoading}
        settings={periodTableSettings}
        fetchEvolutionTableOpenRow={fetchEvolutionTableOpenRow}
        globalProjectionId={globalBudgetId}
        step={defaultPhase}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const getComparisonTable = () => {
    if (enabledComparative) {
      return (
        <ComparisonPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          setShowModal={setShowModal}
          globalProjectionId={globalBudgetId}
          allDimensionsList={allDimensionsList}
          isComparisonLoading={isComparisonLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataComparativeByOverallBudget(tableId, parseInt(globalBudgetId), {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          showProgressBar={showProgressBar}
          currencyData={currencyData}
          localReferenceBase={localReferenceBase}
          tabKey={tabKey}
          tableKey={tableKey}
          defaultPhase={defaultPhase}
          hashComparison={comparativeOptions.length > 0 && hashComparison}
          showTableqp={showTableqp}
          setShowTableqp={setShowTableqp}
        />
      )
    }
    return (
      <ComparisonTable
        globalProjectionComparisonTable={globalBudgetComparisonTable}
        fetchComparisonTableOpenRow={fetchComparisonTableOpenRow}
        loading={isComparisonLoading || tableLoading}
        reportId={tableId}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const tables = {
    evolution: getEvolutionTable(),
    comparison: getComparisonTable(),
  }

  const dataSpreadsheets = {
    ecolution: {
      type_load: 'evolutiva',
      params: {
        pk_report: tableId,
        pk_budget: globalBudgetId,
        step: defaultPhase,
      },
    },
    comparison: {
      type_load: 'comparativa',
      params: {
        pk_report: tableId,
        pk_budget: globalBudgetId,
      },
    },
  }

  const renderChart = () => {
    const renderChartTitle = () => (
      <>
        {t('REPORT_GRAPHIC_TITLE')}
        <SelectReportLine
          rows={globalBudgetChartDefault.rows}
          lineId={globalBudgetChartDefault.lineId}
          onChange={(id) => setReportLineSelectedPlanning(id)}
        />
      </>
    )
    //TODO: VER SI EN ESTE CASO ES POSIBLE AGREGAR EL SELECTOR DE LINEAS
    return (
      <GlobalProjectionLineChart
        title={renderChartTitle()}
        exportTitle={`${t('REPORT_GRAPHIC_TITLE')} - ${t('LABEL_GLOBAL_BUDGET')}`}
        data={[globalBudgetChart]}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={onRefetchComments}
        loadingComments={loadingComments}
        currencyData={currencyData}
      />
    )
  }

  const renderTableAndActions = () => tables[tableKey]

  const handleClick = (key) => {
    setTableId(key)
    setTableKey(TAB_KEYS.TAB_KEY_EVOLUTION)
    setReportLineSelectedPlanning(undefined)
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${tabKey}/${currencyData?.id}/${key}`,
    )
  }

  const handleClickTableType = (key) => {
    setTableKey(key)
    setShowTableqp(false)
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${tabKey}/${currencyData?.id}/${tableId}/${key}`,
    )
  }

  const handleClickBaseComparison = (key) => {
    setLocalReferenceBase({ value: key, id: null })
    setDefaultPhase(key)
    const data = comparativeOptions.filter((el) => el !== key.toLowerCase())
    setOptionsSelected(data)
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${tabKey}/${
        currencyData?.id
      }/${tableId}/${tableKey}/${key.toLowerCase()}`,
    )
  }

  const handleClickBaseEvolution = (key) => {
    setDefaultPhase(key)
    window.history.replaceState(
      null,
      '',
      `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/${tabKey}/${currencyData?.id}/${tableId}/${tableKey}/${key}`,
    )
  }

  return (
    <>
      <Tabs
        defaultActiveKey={tableId}
        onChange={(key) => {
          handleClick(key)
        }}
        size="small"
      >
        {analysisList.count > 0 &&
          analysisList.values
            .filter((report) => report.has_data)
            .map((report) => <Tabs.TabPane tab={report.name} key={report.id} />)}
      </Tabs>
      <Spin spinning={loading || isChartLoading} />
      {tableId && !loading && !isChartLoading && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Space direction="horizontal">
                <TableTypeSelect
                  tableKey={tableKey}
                  setTableKey={handleClickTableType}
                  defaultPhase={defaultPhase}
                  dataPhaseBudget={setPhaseAvailable(stepsBudgetInstance, dataPhaseBudget)}
                  setDefaultPhase={handleClickBaseEvolution}
                />
                {tableKey === TAB_KEYS.TAB_KEY_COMPARATION && (
                  <SelectType.GlobalComparisonSelect
                    localReferenceBase={localReferenceBase}
                    stepsInstance={stepsBudgetInstance}
                    dataPhaseBudget={dataPhaseBudget}
                    localReferenceComparisonBase={localReferenceComparisonBase}
                    comparativeOptions={comparativeOptions}
                    setOptionsSelected={setOptionsSelected}
                    onApply={() => setLocalReferenceComparisonBase(optionsSelected)}
                    optionsSelected={optionsSelected}
                    onChange={(val) => handleClickBaseComparison(val)}
                  />
                )}
              </Space>
            </Col>
            <Col span={24}>{renderTableAndActions()}</Col>
            <Col span={24}>{renderChart()}</Col>
          </Row>
          <DownloadModal
            title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            defaultValue={folder_id}
            dataSpreadsheets={dataSpreadsheets[tableKey]}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  analysisList: reports.selectors.getAnalysisList(state),
  dataPhaseBudget: planning.selectors.getDataPhaseBudget(state),
  globalBudgetComparisonTable: planning.selectors.getGlobalBudgetComparisonTable(state),
  globalBudgetEvolutionTable: planning.selectors.getGlobalBudgetEvolutionTable(state),
  globalBudgetChart: planning.selectors.getGlobalBudgetChart(state),
  globalBudgetChartDefault: planning.selectors.getGlobalBudgetChartDefault(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetComparisonTable: planning.actions.fetchGlobalBudgetComparisonTable,
  fetchGlobalBudgetEvolutionTable: planning.actions.fetchGlobalBudgetEvolutionTable,
  fetchEvolutionTableOpenRow: planning.actions.fetchEvolutionTableOpenRow,
  fetchComparisonTableOpenRow: planning.actions.fetchComparisonTableOpenRow,
  fetchGlobalBudgetChart: planning.actions.fetchGlobalBudgetChart,
  downloadGlobalBudgeEvolutiontXLS: planning.actions.downloadGlobalBudgeEvolutiontXLS,
  downloadGlobalBudgeComparisontXLS: planning.actions.downloadGlobalBudgeComparisontXLS,
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataEvolutionByOverallBudget: planning.actions.fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget: planning.actions.fetchDataComparativeByOverallBudget,
  setReportLineSelectedPlanning: planning.actions.setReportLineSelectedPlanning,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsReports)
