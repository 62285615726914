import { useEffect, useState } from 'react'
import { Alert, Col, message, Row, Space } from 'antd'
import { omitBy } from 'lodash'
import {
  ButtonActionBar,
  Comments,
  PercentageTable,
  ProgressBar,
  Typography,
} from 'modules/core/components'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { handleUnApply } from '../../../../../../../planning/containers/PlanningDetail/components/BaseBudget/components/Percentage/utils'
import { handleApply, handleCellEdit } from './utils'
import { useTranslation } from 'react-i18next'
import { CommentOutlined } from '@ant-design/icons'
import forecast from '../../../../../../index'
import ActionBar from './components/ActionBar'
import configuration from 'modules/configuration'
import login from 'modules/login'
import { generateMessageError } from 'modules/core/utils'

const Percentage = (props) => {
  const {
    loading,
    dataPhase,
    forecastInstance,
    dataRdnPercentageForecast,
    currentRdnSelect,
    businessRulesOption,
    handleRdnChange,
    ruleTypeValue,
    updateTransactionPercentage,
    reloadTablePercentage,
    applyRdnTransactionPercentage,
    unApplyRdnTransactionPercentage,
    setData,
    comments,
    onRefetchComments,
    loadingComments,
    loggedUser,
    setHasComment,
    hasComment,
    createComment,
    removeNewComment,
    showProgressBar,
    canUserABM,
    tabKey,
    showTableqp,
    setShowTableqp,
  } = props
  // Hooks
  let { projectionId, forecastId, periodId } = useParams()
  // State
  const [settings, setSettings] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  // Values
  const baseDataSend = {
    concept_id: forecastInstance?.concept_id,
    period_id: periodId,
    step: dataPhase.key,
  }
  const key = window.location.pathname.replaceAll('/', '-')

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var table = urlParams.get('table')

  useEffect(() => {
    if (currentRdnSelect) {
      window.history.replaceState(
        null,
        '',
        `/forecast/${periodId}/${forecastId}/${forecastInstance?.concept_name}/${projectionId}/${
          dataPhase.key
        }/${tabKey}?rule=${currentRdnSelect}${table ? `&table=${table}` : ''}`,
      )
    }
  }, [
    periodId,
    forecastInstance,
    tabKey,
    dataPhase,
    currentRdnSelect,
    table,
    forecastId,
    projectionId,
  ])

  const normalizedData = (rows) => {
    const filters = {
      ...rows,
      date: rows.Fecha,
      Fecha: undefined,
    }
    return omitBy(filters, (prop) => prop == null)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-${dataPhase.key}-table-percentage`,
      module: `${key}-${dataPhase.key}`,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderTableActions = () => (
    <ButtonActionBar position="space-between">
      <div />
      <Space>
        {(viewComment ||
          comments.filter(
            (el) => el.key === `${key}-${dataPhase.key}-table-percentage` && !el.resolved,
          ).length > 0) && (
          <Comments
            hasComment={hasComment.table}
            onClick={(visible) => {
              setHasComment({ type: 'table', data: visible })
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={comments.filter(
              (el) =>
                (el.key === `${key}-${dataPhase.key}-table-percentage` && !el.resolved) || !el.text,
            )}
            setData={setData}
            loading={loadingComments || isSaving}
            onRefetchComments={onRefetchComments}
            onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
              handleCreate({ text, usersIds, idComment, setIsNewThread })
            }
            onCleanHasComment={() => {
              setHasComment({ type: 'table', data: false })
              setViewComment(false)
            }}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
      </Space>
    </ButtonActionBar>
  )

  const handleClick = (key) => {
    handleRdnChange(key)
    window.history.replaceState(
      null,
      '',
      `/forecast/${periodId}/${forecastId}/${forecastInstance?.concept_name}/${projectionId}/${dataPhase.key}/${tabKey}?rule=${key}`,
    )
  }

  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <Alert
          message={t(
            'PLANNING_PORCENTUAL_TABLE_ONLY_INDIVIDUAL_RULES_INFORMATIVE_ALERT_DESCRIPTION',
          )}
          type="warning"
          showIcon
        />
      </Col>
      <Col span={24}>
        <ActionBar
          rdnDefault={currentRdnSelect}
          rdnOptions={businessRulesOption}
          handleRdnChange={(value) => handleClick(value)}
          onApplyConfirmPopConfirm={() =>
            handleApply({
              applyRdnPercentage: () =>
                applyRdnTransactionPercentage(currentRdnSelect, projectionId, baseDataSend),
              onRefetch: reloadTablePercentage,
            })
          }
          onUnApplyConfirmPopConfirm={() =>
            handleUnApply({
              unApplyRdnPercentage: () =>
                unApplyRdnTransactionPercentage(currentRdnSelect, projectionId, baseDataSend),
              onRefetch: reloadTablePercentage,
            })
          }
          hasApplyOrUnApply={!canUserABM}
        />
        <br />
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={loading} />}
      </Col>
      <Col span={24}>{renderTableActions()}</Col>
      <Col span={24}>
        <PercentageTable
          loading={loading}
          settings={settings}
          setSettings={setSettings}
          dataPercentageTable={dataRdnPercentageForecast}
          onCellEdit={(amount, row, type_change) => {
            const dataSend = {
              ...baseDataSend,
              amount,
              row: normalizedData(row),
              type_change,
              forecast_kpis_id: projectionId,
              business_rule_id: currentRdnSelect,
            }
            handleCellEdit({
              updateTransactionPercentage: () => updateTransactionPercentage(dataSend),
              onRefetch: reloadTablePercentage,
            })
          }}
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          hasCellEdit={canUserABM}
          ruleTypeValue={ruleTypeValue}
          url={`/forecast/${periodId}/${forecastId}/${forecastInstance?.concept_name}/${projectionId}/${dataPhase.key}/${tabKey}`}
          showTableqp={showTableqp}
          setShowTableqp={setShowTableqp}
          ruleId={currentRdnSelect}
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataRdnPercentageForecast: forecast.selectors.getDataRdnPercentageForecast(state),
  hasComment: configuration.selectors.getHasComment(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  updateTransactionPercentage: forecast.actions.updateTransactionPercentageByForecast,
  applyRdnTransactionPercentage: forecast.actions.applyRdnPercentageByForecast,
  unApplyRdnTransactionPercentage: forecast.actions.unApplyRdnPercentageByForecast,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(Percentage)
