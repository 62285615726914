import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkedUpload, generateMessageError, isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, GENERAL, PROJECTION, ROLES } from 'modules/core/constants'
import {
  handleDeleteSelection,
  renderButtonSendName,
  renderFunctionSend,
} from 'modules/core/components/ProjectionComponents/utils'
import { Col, Row, message, notification } from 'antd'
import { ButtonUploadData, TabsTables } from './components'
import {
  CreateTransactionModal,
  PhaseKpis,
  ProjectionUploadModal,
  SpreadsheetsModal,
  ToolsDrawer,
  UserActionsPhases,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { handleDeleteAll } from './utils'
import planning from 'modules/planning'
import forecast from 'modules/forecast'
import control from 'modules/control'
import configuration from 'modules/configuration'
import moment from 'moment'
import login from 'modules/login'
import processes from 'modules/processes'
import _ from 'lodash'

const {
  FORECAST__SALES__BASE__ABM,
  FORECAST__SALES__TOP_DOWN__ABM,
  FORECAST__SALES__BOTTOM_UP__ABM,
  FORECAST__SALES__CLOSING__ABM,
  FORECAST__EXPENSES__BASE__ABM,
  FORECAST__EXPENSES__TOP_DOWN__ABM,
  FORECAST__EXPENSES__BOTTOM_UP__ABM,
  FORECAST__EXPENSES__CLOSING__ABM,
  FORECAST__COSTS__BASE__ABM,
  FORECAST__COSTS__TOP_DOWN__ABM,
  FORECAST__COSTS__BOTTOM_UP__ABM,
  FORECAST__COSTS__CLOSING__ABM,
  FORECAST__HUMAN_RESOURCES__BASE__ABM,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
  FORECAST__OTHER_5__BASE__ABM,
  FORECAST__OTHER_5__TOP_DOWN__ABM,
  FORECAST__OTHER_5__BOTTOM_UP__ABM,
  FORECAST__OTHER_5__CLOSING__ABM,
  FORECAST__OTHER_6__BASE__ABM,
  FORECAST__OTHER_6__TOP_DOWN__ABM,
  FORECAST__OTHER_6__BOTTOM_UP__ABM,
  FORECAST__OTHER_6__CLOSING__ABM,
} = ROLES

const FORECAST__CONCEPT = {
  AMB: {
    [CONCEPTS.IDS.SALES_ID]: {
      [PROJECTION.BASE]: FORECAST__SALES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__SALES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__SALES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__SALES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.EXPENSES_ID]: {
      [PROJECTION.BASE]: FORECAST__EXPENSES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__EXPENSES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__EXPENSES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__EXPENSES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.COSTS_ID]: {
      [PROJECTION.BASE]: FORECAST__COSTS__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__COSTS__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__COSTS__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__COSTS__CLOSING__ABM,
    },
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
      [PROJECTION.BASE]: FORECAST__HUMAN_RESOURCES__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_5_ID]: {
      [PROJECTION.BASE]: FORECAST__OTHER_5__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__OTHER_5__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__OTHER_5__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__OTHER_5__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_6_ID]: {
      [PROJECTION.BASE]: FORECAST__OTHER_6__BASE__ABM,
      [PROJECTION.TOPDOWN]: FORECAST__OTHER_6__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: FORECAST__OTHER_6__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: FORECAST__OTHER_6__CLOSING__ABM,
    },
  },
}

const BaseForecast = ({
  forecastInstanceDetail,
  dataPhase,
  onRefetch,
  status_budget,
  tablePagination,
  fetchForecastKpis,
  forecastKPIs,
  setNextPhase,
  closingForecast,
  periodList,
  fetchDataNestedTableForecastByPhase,
  downloadControlTemplateXLS,
  uploadFileByPhaseForecast,
  applyRuleinstanceForecast,
  unApplyRuleInstanceForecast,
  massiveCreateForecastTransaction,
  fetchAllDimensions,
  fetchDateBlockingByForecast,
  fetchDataRdnPercentageByForecast,
  fetchRulesList,
  deleteAllTransaction,
  loggedUser,
  fetchCommentsList,
  commentsList,
  strictFilter,
  fetchProcessesList,
  uploadForecastExcelFileBy,
  showTableqp,
  setShowTableqp,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const enableKpis = useFeatureIsOn('feature-visible-kpi')
  const abUploadExcelFile = useFeatureIsOn('ab_upload_excel_file')
  const [showPopConfirmSend, setShowPopConfirmSend] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isKpisLoading, setIsKpisLoading] = useState(false)
  const [isTableNestedLoading, setIsTableNestedLoading] = useState(false)
  const [showSpreadsheetsModal, setShowSpreadsheetsModal] = useState(false)
  const [showDrawerRules, setShowDrawerRules] = useState(false)
  const [showDeleteAll, setShowDeleteAll] = useState(false)
  const [isDeletingAll, setIsDeletingAll] = useState(false)
  const [typeOfLoad, setTypeOfLoad] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [dataComments, setDataComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [ruleTypeValue, setRuleTypeValue] = useState(null)

  let { projectionId, forecastId, periodId, tab } = useParams()
  let history = useHistory()
  const { t } = useTranslation()
  const [dateBlocking, setDateBlocking] = useState([])
  const [applyRuleB, setApplyRuleB] = useState(false)

  const [tabKey, setTabKey] = useState(tab || PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY)
  const [businessRuleId, setBusinessRuleId] = useState(null)

  const key = window.location.pathname.replaceAll('/', '-')

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var rule = urlParams.get('rule')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: `${key}-${dataPhase.key}` }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key, dataPhase.key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions, periodId])

  useEffect(() => {
    if (!_.isEmpty(forecastInstanceDetail)) {
      setIsKpisLoading(true)
      fetchForecastKpis({ step: dataPhase.key, forecast_kpis_id: projectionId }).then(() =>
        setIsKpisLoading(false),
      )
    }
    return () => setIsKpisLoading(false)
  }, [fetchForecastKpis, projectionId, dataPhase.key, forecastInstanceDetail])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY) return
    if (!_.isEmpty(forecastInstanceDetail)) {
      setIsTableNestedLoading(true)
      setShowProgressBar(true)
      fetchDataNestedTableForecastByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        ...(!_.isEmpty(strictFilter)
          ? { strict_filter: encodeURIComponent(JSON.stringify(strictFilter)) }
          : {}),
      }).then(() => {
        setIsTableNestedLoading(false)
        setTimeout(() => {
          setShowProgressBar(false)
        }, 2000)
      })
      fetchDateBlockingByForecast({
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        period_id: periodId,
        step: dataPhase.key,
      }).then((resp) => {
        setDateBlocking(resp.payload.data.month_blocking ?? [])
      })
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataNestedTableForecastByPhase,
    projectionId,
    periodId,
    dataPhase.key,
    forecastInstanceDetail,
    tablePagination.page,
    tabKey,
  ])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY) return
    if (!_.isEmpty(forecastInstanceDetail) && businessRuleId) {
      setIsTableNestedLoading(true)
      setShowProgressBar(true)
      fetchDataRdnPercentageByForecast({
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        period_id: periodId,
        step: dataPhase.key,
        business_rule_id: businessRuleId,
      }).then(() => {
        setIsTableNestedLoading(false)
        setTimeout(() => {
          setShowProgressBar(false)
        }, 2000)
      })
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataRdnPercentageByForecast,
    projectionId,
    periodId,
    dataPhase.key,
    forecastInstanceDetail,
    tabKey,
    businessRuleId,
  ])

  useEffect(() => {
    fetchRulesList({
      period_id: periodId,
      type: 'forecast',
      pk: projectionId,
      status_budget: dataPhase.key,
    }).then((resp) => {
      const result = resp.payload.data
      const sortedRules = result.results
        .filter((el) => el.formula_type === 'individual')
        .sort((a, b) => {
          if (a.isApplied === b.isApplied) {
            return 0
          }
          return a.isApplied ? -1 : 1
        })
      if (!_.isEmpty(sortedRules)) {
        if (rule) {
          setBusinessRuleId(parseInt(rule))
        } else {
          setBusinessRuleId(sortedRules[0].id)
        }
        setRuleTypeValue(sortedRules[0].type_value)
      }
    })
  }, [fetchRulesList, periodId, projectionId, dataPhase.key, rule])

  const verifyPermission = (instance) => {
    const permission = [
      PROJECTION.GLOBAL_FORECAST.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(instance?.concept_id.toString())) {
      return isUserAllowed(
        FORECAST__CONCEPT.AMB[instance?.concept_id][
          !_.isEmpty(instance) && instance?.step.toUpperCase()
        ],
      )
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const isFinished = forecastInstanceDetail?.step !== dataPhase.key
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const canUserABM = !_.isEmpty(forecastInstanceDetail)
    ? verifyPermission(forecastInstanceDetail)
    : true

  const reloadTableNested = () => {
    setIsTableNestedLoading(true)
    setIsKpisLoading(true)
    setShowProgressBar(true)
    Promise.all([
      fetchForecastKpis({ step: dataPhase.key, forecast_kpis_id: projectionId }),
      fetchDataNestedTableForecastByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: forecastInstanceDetail?.concept_id,
        forecast_kpis_id: projectionId,
        page: tablePagination.page,
        ...(!_.isEmpty(strictFilter)
          ? { strict_filter: encodeURIComponent(JSON.stringify(strictFilter)) }
          : {}),
      }),
    ]).then(() => {
      setIsTableNestedLoading(false)
      setIsKpisLoading(false)
      setTimeout(() => {
        setShowProgressBar(false)
      }, 2000)
      message.success(t('UPDATE_INFORMATION_MESSAGE'), 8)
    })
  }

  const onClose = () => {
    setIsDeletingAll(false)
    setShowDeleteAll(false)
  }

  const onSuccessUploadFile = (response) => {
    if (abUploadExcelFile) {
      reloadTableNested()
    } else {
      checkedUpload({
        fetchProcessesList,
        setIsDisable,
        dataProjection: {
          id: projectionId,
          module: 'forecast',
        },
        onRefetch: reloadTableNested,
      })
    }

    notification.success({
      message: t('FEEDBACK_EVERYTHING_READY'),
      description: (
        <>
          <span>{t('FEEDBACK_UPLOAD_DATA_FILE_SUCCESS')}</span>
          <span
            onClick={() => history.push(onHandleRedirect())}
            style={{ color: '#0047ba', cursor: 'pointer' }}
          >
            {t('LABEL_PROCESS_UPLOAD')}
          </span>
        </>
      ),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
    // reloadTableNested()
  }

  const onFailUploadFile = (error) => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: generateMessageError(error),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
  }

  const onChangeTypeOfLoad = (e) => {
    setTypeOfLoad(e.target.value)
  }

  // console.log(dateBlockingByForecast)

  const reloadTablePercentage = () => {
    fetchDataRdnPercentageByForecast({
      concept_id: forecastInstanceDetail?.concept_id,
      forecast_kpis_id: projectionId,
      page: tablePagination.page,
      period_id: periodId,
      step: dataPhase.key,
      business_rule_id: businessRuleId,
    })
  }

  const handleClose = () => {
    setShowDrawerRules(false)
    if (applyRuleB) {
      console.log('applyRule', applyRuleB)
      reloadTableNested()
    }
    setApplyRuleB(false)
  }

  const handleApplyRule = (ruleId) => {
    setApplyRuleB(true)

    return applyRuleinstanceForecast(projectionId, ruleId, {
      concept_id: forecastInstanceDetail?.concept_id,
      period_id: periodId,
      step: forecastInstanceDetail?.step,
    })
  }

  const handleUnApplyRule = (ruleId) => {
    setApplyRuleB(true)

    return unApplyRuleInstanceForecast(projectionId, ruleId, {
      concept_id: forecastInstanceDetail?.concept_id,
      period_id: periodId,
      step: forecastInstanceDetail?.step,
    })
  }

  const onHandleUpload = (handleUploadProps) => {
    const { fileList, typeOfLoad } = handleUploadProps
    if (abUploadExcelFile) {
      const { concept_id } = forecastInstanceDetail

      // TODO: Check if the name is necessary
      return uploadForecastExcelFileBy(forecastId, fileList, {
        name: 'Forecast',
        forecast_kpis: projectionId,
        status_budget: dataPhase.key.toUpperCase(),
        concept: concept_id,
        period: periodId,
        date_format: 'yyyy-mm-dd',
        type_of_load: typeOfLoad,
        upload_from: 'manual_loading',
        type_of_file: 'FORECAST',
      })
    }

    uploadFileByPhaseForecast(fileList, {
      forecast_kpis_id: projectionId,
      concept_id: forecastInstanceDetail?.concept_id,
      type_file: 'forecast',
      type_of_load: typeOfLoad,
    })
  }

  const onHandleRedirect = () => {
    if (abUploadExcelFile) {
      return `/forecast/${periodId}/${forecastId}/${forecastInstanceDetail?.concept_name}/${dataPhase.key}/workflow/${projectionId}?type=forecast&keyExecution=${forecastId}-${dataPhase.key}`
    }
    return '/procesosdecarga'
  }

  const onHandleActivity = () => {
    return `/forecast/${periodId}/${forecastId}/${forecastInstanceDetail?.concept_name}/${dataPhase.key}/workflow/${projectionId}?type=forecast&keyExecution=${forecastId}-${dataPhase.key}`
  }

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserActionsPhases
            onConfirmPopConfirm={() => {
              setShowPopConfirmSend(false)
              renderFunctionSend({
                step: forecastInstanceDetail?.step,
                setILoading: setIsDataLoading,
                setNextPhase: () => setNextPhase({ forecast_kpis_id: projectionId, status_budget }),
                closing: () =>
                  closingForecast({ forecast_kpis_id: projectionId, is_closing: true }),
                onRefetch,
                onClose: () => setIsDataLoading(false),
              })
            }}
            onCancelPopconfirm={() => setShowPopConfirmSend(false)}
            showPopConfirmSend={showPopConfirmSend}
            onClickSend={() => setShowPopConfirmSend(true)}
            buttonName={renderButtonSendName({
              step: forecastInstanceDetail?.step,
              dataPhasekey: dataPhase.key,
              is_closing: forecastInstanceDetail?.is_closing,
              buttonName: dataPhase.buttonName,
            })}
            disabled={isFinished || forecastInstanceDetail?.is_closing || !canUserABM || isDisable}
            dataPhase={dataPhase}
            loading={isDataLoading}
            is_closing={forecastInstanceDetail?.is_closing}
            buttonUploadData={
              <ButtonUploadData
                onClickFile={() => setShowUploadModal(true)}
                onClickSpreadsheets={() => setShowSpreadsheetsModal(true)}
                disabled={
                  isFinished ||
                  forecastInstanceDetail?.is_closing ||
                  !canUserABM ||
                  isDataLoading ||
                  isDisable
                }
              />
            }
            onClickActivity={() =>
              history.push(
                `/forecast/${periodId}/${forecastId}/${forecastInstanceDetail?.concept_name}/${dataPhase.key}/actividad/${projectionId}?type=${PROJECTION.FORECAST}&module=forecastDetail`,
              )
            }
            onClickApply={() => setShowDrawerRules(true)}
            loadingDelete={isDeletingAll}
            visible={showDeleteAll}
            onCancelDeleteAll={() => setShowDeleteAll(false)}
            onConfirmDeleteAll={() =>
              handleDeleteAll({
                setIsLoading: setIsDeletingAll,
                deleteAll: () => deleteAllTransaction(projectionId, dataPhase.key),
                onRefetch: () =>
                  fetchDataNestedTableForecastByPhase({
                    step: dataPhase.key,
                    period_id: periodId,
                    concept_id: forecastInstanceDetail?.concept_id,
                    forecast_kpis_id: projectionId,
                    page: tablePagination.page,
                    ...(!_.isEmpty(strictFilter)
                      ? { strict_filter: encodeURIComponent(JSON.stringify(strictFilter)) }
                      : {}),
                  }),
                onClose,
              })
            }
            onClickDeleteAll={() => setShowDeleteAll(true)}
            onClickCreate={() => setShowCreateModal(true)}
            hasCreate={canUserABM}
            extraButtons={{
              module_name: 'forecast',
              concept: forecastInstanceDetail?.concept_id,
              submodule_name: projectionId,
            }}
            type="forecast"
            module="forecast"
            isDisable={isDisable}
            currentStep={forecastInstanceDetail?.step}
            onClickUpload={() => history.push(onHandleActivity())}
          />
        </Col>
        {enableKpis && (
          <Col span={24}>
            <PhaseKpis
              kpiLoading={isKpisLoading || isDisable}
              dataKpis={forecastKPIs}
              userDetail={loggedUser}
              setData={setDataComments}
              comments={dataComments}
              onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
              loading={isLoading}
              phase={dataPhase.key}
            />
          </Col>
        )}
        <Col span={24}>
          <TabsTables
            tabKey={tabKey}
            setTabKey={setTabKey}
            canUserABM={canUserABM}
            isDisable={isDisable}
            forecastInstanceDetail={forecastInstanceDetail}
            onConfirmDeletSelection={() => handleDeleteSelection()}
            isDataLoading={isDataLoading}
            dataPhase={dataPhase}
            dataPeriod={dataPeriod}
            isTableNestedLoading={isTableNestedLoading || isDisable}
            reloadTableNested={reloadTableNested}
            isFinished={isFinished}
            businessRuleId={businessRuleId}
            setBusinessRuleId={setBusinessRuleId}
            setRuleTypeValue={setRuleTypeValue}
            ruleTypeValue={ruleTypeValue}
            reloadTablePercentage={reloadTablePercentage}
            setData={setDataComments}
            comments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
            loading={isLoading}
            showProgressBar={showProgressBar}
            visibleTotal={_.isEmpty(strictFilter)}
            showTableqp={showTableqp}
            setShowTableqp={setShowTableqp}
          />
        </Col>
      </Row>
      <ProjectionUploadModal
        title={t('ACTION_UPLOAD_DATA')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={(fileList, typeOfLoad) => onHandleUpload({ fileList, typeOfLoad })}
        onSuccessUpload={onSuccessUploadFile}
        onFailUpload={onFailUploadFile}
        downloadTemplateFile={() =>
          downloadControlTemplateXLS(
            t('FORECAST_TEMPLATE_NAME', {
              name: forecastInstanceDetail?.name,
              periodName: dataPeriod.name,
              date: moment().format('lll'),
            }),
            { concept_id: forecastInstanceDetail?.concept_id, period_id: periodId },
          )
        }
        enabledTypeOfLoad={true}
        extraInformativeCurrency={true}
      />
      <SpreadsheetsModal
        visible={showSpreadsheetsModal}
        onCancel={() => {
          setShowSpreadsheetsModal(false)
          setTypeOfLoad(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
        }}
        onRefetch={reloadTableNested}
        dataSpreadsheets={{
          type_load: 'forecast_update',
          params: {
            forecast_kpis_id: projectionId,
            concept_id: forecastInstanceDetail?.concept_id,
            type_of_load: typeOfLoad,
          },
        }}
        onChangeTypeOfLoad={onChangeTypeOfLoad}
        typeOfLoad={typeOfLoad}
        enabledTypeOfLoad={true}
        periodId={periodId}
        conceptId={forecastInstanceDetail?.concept_id}
        setIsDisable={setIsDisable}
        dataProjection={{
          id: projectionId,
          module: 'forecast',
        }}
      />
      <ToolsDrawer
        onRefetchDataTable={reloadTableNested}
        visible={showDrawerRules}
        onClose={() => handleClose()}
        globalProjectionId={projectionId}
        type="forecast"
        onRefetch={onRefetch}
        applyRule={(ruleId) => handleApplyRule(ruleId)}
        unApplyRule={(ruleId) => handleUnApplyRule(ruleId)}
        disabledRule={isFinished || forecastInstanceDetail?.is_closing || !canUserABM || isDisable}
        typeModule={GENERAL.MODULES_KEYS.MODULE_FORECAST}
        moduleId={projectionId}
        periodId={periodId}
        hasRule={true}
        extraParams={{
          status_budget: dataPhase.key,
        }}
      />

      <CreateTransactionModal
        title={t('ACTION_CREATE_TRANSACTION')}
        dataPeriod={dataPeriod}
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onRefetch={reloadTableNested}
        onCreateTransaction={(values) =>
          massiveCreateForecastTransaction({
            ...values,
            period_id: periodId,
            concept_id: forecastInstanceDetail?.concept_id,
            type_sync_data: typeOfLoad,
            forecast_kpis_id: projectionId,
          })
        }
        dateBlocking={dateBlocking}
        onChangeTypeOfLoad={onChangeTypeOfLoad}
        typeOfLoad={typeOfLoad}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  forecastKPIs: forecast.selectors.getForecastKpis(state),
  periodList: planning.selectors.getPeriodList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  setNextPhase: forecast.actions.setNextPhase,
  closingForecast: forecast.actions.closingForecast,
  fetchForecastKpis: forecast.actions.fetchForecastKpis,
  fetchDataNestedTableForecastByPhase: forecast.actions.fetchDataNestedTableForecastByPhase,
  fetchExpandNestedTableRow: forecast.actions.fetchExpandNestedTableRow,
  downloadControlTemplateXLS: control.actions.downloadControlTemplateXLS,
  uploadFileByPhaseForecast: forecast.actions.uploadFileByPhaseForecast,
  applyRuleinstanceForecast: forecast.actions.applyRuleinstanceForecast,
  unApplyRuleInstanceForecast: forecast.actions.unApplyRuleInstanceForecast,
  massiveCreateForecastTransaction: forecast.actions.massiveCreateForecastTransaction,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchDateBlockingByForecast: forecast.actions.fetchDateBlockingByForecast,

  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataRdnPercentageByForecast: forecast.actions.fetchDataRdnPercentageByForecast,
  fetchRulesList: configuration.actions.fetchRulesList,
  deleteAllTransaction: forecast.actions.deleteTransactionFilterByForecast,
  fetchCommentsList: configuration.actions.fetchCommentsList,
  fetchProcessesList: processes.actions.fetchProcessesList,
  uploadForecastExcelFileBy: forecast.actions.uploadForecastExcelFileBy,
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseForecast)
