import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodNavigator } from 'modules/core/components'
import { Tabs, Spin } from 'antd'
import { isUserAllowed, getOperationsByKeys } from 'modules/core/utils'
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants'
import GlobalBudgetList from '../GlobalBudgetList/GlobalBudgetList'
import PlanningList from '../PlanningList/PlanningList'
import configuration from 'modules/configuration'
import _ from 'lodash'

const {
  PLANNING__EXPENSES,
  PLANNING__SALES,
  PLANNING__HUMAN_RESOURCES,
  PLANNING__COSTS,
  PLANNING__OTHER_5,
  PLANNING__OTHER_6,
  PLANNING__GLOBAL_BUDGET,
} = ROLES

const PlanningHome = ({ budgetTypeList, fetchBudgetTypeList }) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  let { periodId, conceptName } = useParams()
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [tabKey, setTabKey] = useState(conceptName || 'Ventas')
  const { t } = useTranslation()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `?tab=${tabKey}` : ''

  useEffect(() => {
    setIsScreenLoading(true)
    fetchBudgetTypeList().then((response) => {
      const aux = response.payload.data.concepts.filter((el) => PANEL__ACCESS[el.id])
      getTabKey(aux)
      setIsScreenLoading(false)
    })
    // eslint-disable-next-line
  }, [fetchBudgetTypeList])

  const PANEL__ACCESS = {
    [PROJECTION.GLOBAL_BUDGET]: isUserAllowed(getOperationsByKeys([PLANNING__GLOBAL_BUDGET])),
    [CONCEPTS.IDS.SALES_ID]: isUserAllowed(getOperationsByKeys([PLANNING__SALES])),
    [CONCEPTS.IDS.EXPENSES_ID]: isUserAllowed(getOperationsByKeys([PLANNING__EXPENSES])),
    [CONCEPTS.IDS.COSTS_ID]: isUserAllowed(getOperationsByKeys([PLANNING__COSTS])),
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: isUserAllowed(
      getOperationsByKeys([PLANNING__HUMAN_RESOURCES]),
    ),
    [CONCEPTS.IDS.OTHER_5_ID]: isUserAllowed(getOperationsByKeys([PLANNING__OTHER_5])),
    [CONCEPTS.IDS.OTHER_6_ID]: isUserAllowed(getOperationsByKeys([PLANNING__OTHER_6])),
  }

  const verifyPermission = (concept) => {
    const permission = [
      PROJECTION.GLOBAL_BUDGET.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    const value = PANEL__ACCESS[concept.id]
    if (value === undefined && !permission.includes(concept.id.toString()) && extraConcept) {
      return true
    }
    return value
  }

  const filterByRole = budgetTypeList?.values.filter((el) => verifyPermission(el))

  const getTabKey = (data) => {
    if (_.isEmpty(data)) {
      setTabKey('Ventas')
    } else {
      if (data.find((el) => el.name === conceptName) || conceptName === PROJECTION.GLOBAL_BUDGET) {
        setTabKey(conceptName)
      } else {
        setTabKey(showDefaultPanelKey(data[0]))
      }
    }
  }

  const showDefaultPanelKey = (firstElement) => {
    if (firstElement && verifyPermission(firstElement)) {
      return firstElement.name
    } else if (PANEL__ACCESS[PROJECTION.GLOBAL_BUDGET]) {
      return PROJECTION.GLOBAL_BUDGET
    }
  }

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_PLANNING') }]} />

  const handleClick = (key) => {
    setTabKey(key)
    window.history.replaceState(null, '', `/planificacion/${periodId}/${key}`)
  }

  return (
    <SiderLayout extraKey={key}>
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      {!isScreenLoading && (
        <Tabs defaultActiveKey={tabKey} onChange={(key) => handleClick(key)} size="small">
          {budgetTypeList?.values.map(
            (concept) =>
              verifyPermission(concept) && (
                <Tabs.TabPane tab={t(concept.name)} key={concept.name}>
                  {tabKey === concept.name && (
                    <PlanningList periodId={periodId} concept={concept} />
                  )}
                </Tabs.TabPane>
              ),
          )}
          {PANEL__ACCESS[PROJECTION.GLOBAL_BUDGET] && (
            <Tabs.TabPane tab={t('LABEL_GLOBAL_BUDGET')} key={PROJECTION.GLOBAL_BUDGET}>
              <GlobalBudgetList />
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningHome)
