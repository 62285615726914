import React, { useEffect, useState } from 'react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Col, Dropdown, Menu, message, notification, Row, Space, Spin } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import {
  Button,
  ColumnsAndRowsSelections,
  DownloadModal,
  FavouriteEditModal,
  ProgressBar,
  SelectType,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GENERAL, PROJECTION, INTEGRATIONS } from '../../../../../../../../../core/constants'
import { ComparativeSwitch, ComparativeTable } from './components'
import {
  handleSave,
  handleSavePrimary,
} from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import PivotTableUX from '../../../../../../../../../../components/PivotTable/PivotTableUX'
import TableRenderers from '../../../../../../../../../../components/PivotTable/Renderers/Comparative/TableRenderers'
import TableActions from '../TableActions/TableActions'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import control from 'modules/control'
import login from 'modules/login'
import moment from 'moment'
import _, { isEqual } from 'lodash'
import { handleDelete } from '../../utils'
import { generateMessageError, getHashTable } from 'modules/core/utils'

import PlaneTableUX from 'components/PivotTable/PlaneTableUX'
import PlaneTableRenderers from 'components/PivotTable/Renderers/Comparative/Plane'
import ReportChart from '../../../ReportChart/ReportChart'

const DEFAULT_FORMAT_CREATE = {
  'Suma (Decimales)': 'float',
  'Suma (Enteros)': 'int',
  'Suma (Miles)': 't',
  'Suma (Millones)': 'm',
}

const DEFAULT_FORMAT_SET = {
  float: 'Suma (Decimales)',
  int: 'Suma (Enteros)',
  t: 'Suma (Miles)',
  m: 'Suma (Millones)',
}

const getRowAuxName = (rows, allDimensionsList) => {
  return rows.map((row) => {
    if (row === 'Linea de reporte') {
      return 'Linea de reporte'
    } else if (row === 'Proyecciones') {
      return 'Proyecciones'
    } else if (row === 'Fecha') {
      return 'Fecha'
    } else if (row === 'Moneda Origen') {
      return 'Moneda Origen'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(row) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getColsAuxName = (columns, allDimensionsList) => {
  return columns.map((col) => {
    if (col === 'Linea de reporte') {
      return 'Linea de reporte'
    } else if (col === 'Proyecciones') {
      return 'Proyecciones'
    } else if (col === 'Fecha') {
      return 'Fecha'
    } else if (col === 'Moneda Origen') {
      return 'Moneda Origen'
    } else {
      const dimension = allDimensionsList.find((el) => parseInt(col) === el.id)
      return dimension ? dimension.name_origin : ''
    }
  })
}

const getRowAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.rows.map((row) => {
      if (row === 'Linea de reporte') {
        return 'Linea de reporte'
      } else if (row === 'Proyecciones') {
        return 'Proyecciones'
      } else if (row === 'Fecha') {
        return 'Fecha'
      } else if (row === 'Moneda Origen') {
        return 'Moneda Origen'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === row).id
        return id.toString()
      }
    })
  )
}

const getColsAuxId = (settings, allDimensionsList) => {
  return (
    !_.isEmpty(settings) &&
    settings.cols.map((col) => {
      if (col === 'Linea de reporte') {
        return 'Linea de reporte'
      } else if (col === 'Proyecciones') {
        return 'Proyecciones'
      } else if (col === 'Fecha') {
        return 'Fecha'
      } else if (col === 'Moneda Origen') {
        return 'Moneda Origen'
      } else {
        const id = allDimensionsList.find((el) => el.name_origin === col)
          ? allDimensionsList.find((el) => el.name_origin === col).id
          : null
        return id.toString()
      }
    })
  )
}

const Comparative = ({
  loading,
  selectedFilters,
  selectedDates,
  selectedPeriod,
  groupProjection,
  periodTableSettings,
  setPeriodTableSettings,
  referenceBase,
  setReferenceBase,
  referenceComparison,
  setReferenceComparison,
  keyComparisonTable,
  setKeyComparisonTable,
  onRefetchComparative,
  setTablePagination,
  onRefetch,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  dataComparative: { columns1, rows1, lineId },
  downloadComparativeXLS,
  dataComparativeByReport,
  createLayout,
  fetchAllDimensions,
  fetchLayoutList,
  layoutList,
  allDimensionsList,
  deleteLayout,
  hasComment,
  setHasComment,
  loggedUser,
  createComment,
  removeNewComment,
  isProgressLoading,
  showProgressBar,
  currencyData,
  localReferenceBase,
  setLocalReferenceBase,
  dataComparativeChart,
  dataComparativeChartDefault,
  fetchDataComparativeChart,
  overallBudgetSelectAnalysis,
  tabKey,
  type,
  showTableqp,
  setShowTableqp,
}) => {
  // Feature flag
  const enabledComparativeTable = useFeatureIsOn('feature-react-pivot-comparative')
  const enabledPlaneTable = useFeatureIsOn('feature-plane-table-custom')
  const formatBaseNumber = useFeatureValue('format-number-table', 'Suma (Decimales)')

  // Hooks
  const { t } = useTranslation()
  let { reportId, periodId } = useParams()

  // State
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  // TODO: FOR REVAMP TABLE
  const [settings, setSettings] = useState({})
  const [localReferenceComparison, setLocalReferenceComparison] = useState(referenceComparison)
  const [valueReferenceBase, setValueReferenceBase] = useState('REAL')
  const [showPopover, setShowPopover] = useState(false)
  const [favouriteName, setFavouriteName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLayoutLoading, setIsLayoutLoading] = useState(false)
  const [viewSelect, setViewSelect] = useState({})
  const [viewComment, setViewComment] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [newName, setNewName] = useState('')
  const [rowId, setRowId] = useState(null)
  const [optionSelected, setOptionSelected] = useState([])
  const [hash, setHash] = useState(null)

  // Values
  const { dataSource, linesOrder, linesTotal, linesRatio, linesRatioN } = dataComparativeByReport

  const paramsDownload = {
    period_id: periodId,
    filter_date: selectedDates,
    filter: selectedFilters,
    reference_base: enabledComparativeTable ? localReferenceBase : referenceBase,
    reference_comparative: referenceComparison,
    report_type: keyComparisonTable,
  }

  const projectionsSelect = {
    BUDGET: overallBudgetSelectAnalysis.overall_budgets,
    FORECAST: overallBudgetSelectAnalysis.forecast_overall_budgets,
    BUDGET_PREV_PERIOD: overallBudgetSelectAnalysis.prev_period_overall_budgets,
    FORECAST_PREV_PERIOD: overallBudgetSelectAnalysis.prev_period_forecast_overall_budgets,
  }

  // Methods
  const handleComparisonSelection = (values) => {
    setReferenceComparison(values)
  }

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var comparison = urlParams.get('comparison')
  var table = urlParams.get('table')

  const dataHash =
    optionSelected.length > 0
      ? JSON.stringify(optionSelected)
      : JSON.stringify(localReferenceComparison)

  let hashComparison = Buffer.from(dataHash).toString('base64')

  useEffect(() => {
    if (comparison) {
      const json = Buffer.from(comparison, 'base64').toString()
      const dataComparison = JSON.parse(json)
      setLocalReferenceComparison(dataComparison)
    }
    if (table) {
      const data = !isEqual(hash, table) && hash !== null ? hash : table
      const json = Buffer.from(data, 'base64').toString()
      const dataTable = JSON.parse(json)
      setSettings((prevstate) => {
        return {
          ...prevstate,
          ...dataTable.config,
          hiddenFromDragDrop: ['amount', 'price', 'quantity'],
        }
      })
    }
  }, [comparison, table, hash])

  useEffect(() => {
    // if ((localReferenceComparison.length > 0 || optionSelected.length > 0) && showTableqp) {
    const { data, tableOptions, hiddenFromDragDrop, ...restProps } = settings
    if (!_.isEmpty(restProps) && showTableqp) {
      window.history.replaceState(
        null,
        '',
        `/analisis/${periodId}/reportesplika/${reportId}/${type}/${currencyData?.id}/${tabKey}/${
          localReferenceBase.id
            ? `${localReferenceBase.value}-${localReferenceBase.id}`
            : localReferenceBase.value
        }?comparison=${
          optionSelected.length === 0 && comparison ? comparison : hashComparison
        }${`&table=${getHashTable(settings)}`}`,
      )
    }
  }, [
    currencyData,
    periodId,
    hashComparison,
    comparison,
    type,
    localReferenceBase,
    reportId,
    tabKey,
    optionSelected,
    settings,
    showTableqp,
  ])

  useEffect(() => {
    const selectComparison = referenceComparison.reduce((acum, data) => {
      const auxData = data.split('-')
      if (auxData[1]) {
        let element = projectionsSelect[auxData[0].toUpperCase()].find(
          (el) => el.id === parseInt(auxData[1]),
        )
        if (!element) {
          element = projectionsSelect[`${auxData[0].toUpperCase()}_PREV_PERIOD`].find(
            (el) => el.id === parseInt(auxData[1]),
          )
        }
        acum = { ...acum, [`${auxData[0]}-${element.name}`]: { value: auxData[0], id: auxData[1] } }
      } else {
        acum = { ...acum, [auxData[0]]: { value: auxData[0], id: null } }
      }
      return acum
    }, {})

    let elementBase =
      localReferenceBase.id &&
      projectionsSelect[localReferenceBase.value.toUpperCase()].find(
        (el) => el.id === parseInt(localReferenceBase.id),
      )

    if (!elementBase) {
      elementBase =
        localReferenceBase.id &&
        projectionsSelect[`${localReferenceBase.value.toUpperCase()}_PREV_PERIOD`].find(
          (el) => el.id === parseInt(localReferenceBase.id),
        )
    }

    const selectBase = elementBase
      ? {
          [`${
            localReferenceBase.id
              ? `${localReferenceBase.value}-${elementBase.name}`
              : localReferenceBase.value
          }`]: localReferenceBase,
        }
      : { [localReferenceBase.value]: localReferenceBase }

    setIsLoadingChart(true)
    fetchDataComparativeChart(reportId, {
      period_id: periodId,
      filter_date: selectedDates,
      filter: selectedFilters,
      type_report: { ...selectBase, ...selectComparison },
      currency_id: currencyData?.id,
    }).then(setIsLoadingChart(false))
  }, [
    fetchDataComparativeChart,
    reportId,
    periodId,
    selectedDates,
    selectedFilters,
    referenceComparison,
    currencyData?.id,
    localReferenceBase,
  ])

  useEffect(() => {
    setPeriodTableColumns(columns1)
  }, [columns1])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  useEffect(() => {
    setIsLayoutLoading(true)
    fetchLayoutList('reports', `${periodId}-${reportId}-coparations`).then(() => {
      setIsLayoutLoading(false)
      !table && setSettings({})
    })
  }, [fetchLayoutList, periodId, reportId, table])

  useEffect(() => {
    const defaultLayout = layoutList.values.find((el) => el.primary)
    setViewSelect(defaultLayout)
  }, [layoutList.values])

  useEffect(() => {
    let value = 'REAL'
    if (localReferenceBase.value === PROJECTION.REAL) {
      value = 'REAL'
    } else if (localReferenceBase.value === PROJECTION.BUDGET) {
      const findValue =
        projectionsSelect.BUDGET.length > 0
          ? projectionsSelect.BUDGET.find(
              (it) => it.id.toString() === localReferenceBase.id.toString(),
            )
          : null
      const prevPeriodValue =
        projectionsSelect.BUDGET_PREV_PERIOD.length > 0
          ? projectionsSelect.BUDGET_PREV_PERIOD.find(
              (it) => it.id.toString() === localReferenceBase.id.toString(),
            )
          : null
      if (findValue || prevPeriodValue) {
        value = `Presupuesto - ${findValue ? findValue.name : prevPeriodValue.name}`
      }
    } else if (localReferenceBase.value === PROJECTION.FORECAST) {
      const findValue =
        projectionsSelect.FORECAST.length > 0
          ? projectionsSelect.FORECAST.find(
              (it) => it.id.toString() === localReferenceBase.id.toString(),
            )
          : null
      const prevPeriodForecast =
        projectionsSelect.FORECAST_PREV_PERIOD.length > 0
          ? projectionsSelect.FORECAST_PREV_PERIOD.find(
              (it) => it.id.toString() === localReferenceBase.id.toString(),
            )
          : null
      if (findValue || prevPeriodForecast) {
        value = `Forecast - ${findValue ? findValue.name : prevPeriodForecast.name}`
      }
    }

    const defaultTableOptions = {
      linesOrder: linesOrder,
      linesTotal: linesTotal,
      linesRatio: linesRatio,
      linesRatioN: linesRatioN,
      referenceBase: valueReferenceBase,
    }

    setValueReferenceBase(value)
    if (!_.isEmpty(settings)) {
      setSettings((prevState) => ({
        ...prevState,
        tableOptions: { ...prevState.tableOptions, ...defaultTableOptions, referenceBase: value },
      }))
    }
    // eslint-disable-next-line
  }, [
    localReferenceBase,
    groupProjection.BUDGET,
    groupProjection.FORECAST,
    linesOrder,
    linesTotal,
    linesRatio,
    linesRatioN,
    valueReferenceBase,
  ])

  useEffect(() => {
    if (
      layoutList.values.length > 0 &&
      !_.isEmpty(viewSelect) &&
      allDimensionsList.length > 0 &&
      !table
    ) {
      setSettings((prevState) => {
        return {
          ...prevState,
          ...viewSelect.config,
          hiddenFromDragDrop: ['amount', 'price', 'quantity'],
          cols:
            getColsAuxName(viewSelect.config.columns, allDimensionsList).length > 0
              ? getColsAuxName(viewSelect.config.columns, allDimensionsList)
              : ['date'],
          rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
          aggregatorName: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
          vals: [viewSelect.config.default_value],
        }
      })
    }
  }, [allDimensionsList, layoutList.values, viewSelect, table])

  useEffect(() => {
    setSettings((prevState) => ({
      ...prevState,
      data: dataSource,
      tableOptions: {
        ...prevState.tableOptions,
        linesOrder: linesOrder,
        linesTotal: linesTotal,
        linesRatio: linesRatio,
        linesRatioN: linesRatioN,
        referenceBase: valueReferenceBase,
      },
    }))
  }, [dataSource, valueReferenceBase])

  const onClose = () => {
    setIsSaving(false)
    setShowPopover(false)
    setFavouriteName('')
  }

  const onCreate = () => {
    const data = {
      row_order: 0,
      column_order: 1,
      default_value: settings.vals[0],
      default_format: DEFAULT_FORMAT_CREATE[settings.aggregatorName],
      rows: getRowAuxId(settings, allDimensionsList),
      columns: getColsAuxId(settings, allDimensionsList),
    }

    return createLayout('reports', `${periodId}-${reportId}-coparations`, favouriteName, {
      config: data,
    })
  }

  const defaultData = !_.isEmpty(viewSelect) && {
    ...viewSelect.config,
    rows: getRowAuxName(viewSelect.config.rows, allDimensionsList),
    columns: getColsAuxName(viewSelect.config.columns, allDimensionsList),
    default_format: DEFAULT_FORMAT_SET[viewSelect.config.default_format],
  }
  const defaultSettings = !_.isEmpty(settings) && {
    rows: settings?.rows,
    columns: settings?.cols || ['Fecha', 'Proyecciones'],
    row_order: settings?.row_order,
    column_order: settings?.column_order,
    default_value: settings?.vals ? settings.vals[0] : '',
    default_format: settings?.aggregatorName,
  }

  const onSavePrimary = (data) => {
    const dataAux = layoutList.values.find((el) => el.name === data.name)
    return createLayout('reports', `${periodId}-${reportId}-coparations`, dataAux.name, {
      ...dataAux,
      primary: true,
    })
  }

  const getReferenceBase = () => {
    if (enabledComparativeTable) {
      return localReferenceBase
    }
    return referenceBase
  }

  const setReferenceBase_ = (value) => {
    setLocalReferenceBase(value)
    setReferenceBase(value)
  }

  const setReferenceComparison_ = (value) => {
    setOptionSelected(value)
    setLocalReferenceComparison(value)
  }

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${commentKey}-table-comparison`,
      module: commentKey,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }
  const renderTable = () => {
    if (enabledPlaneTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PlaneTableUX
                renderers={Object.assign({}, PlaneTableRenderers)}
                data={dataSource}
                onChange={(s) => {
                  setSettings(s)
                  setHash(getHashTable(s))
                  setShowTableqp(true)
                }}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={['Fecha', 'Proyecciones']}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter((el) => !['amount', 'price', 'quantity'].includes(el))
                }
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  referenceBase: valueReferenceBase,
                }}
                tableFilter={{}}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }

    if (enabledComparativeTable) {
      return (
        <Spin spinning={loading}>
          <Dropdown
            trigger={['contextMenu']}
            overlay={
              <Menu>
                {GENERAL.OPTION_COMMENT.map((el) => (
                  <Menu.Item
                    key={el.key}
                    icon={<CommentOutlined />}
                    onClick={() => {
                      setViewComment(true)
                      setHasComment({ type: 'table', data: !hasComment.table })
                    }}
                  >
                    {t(el.label)}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <div style={{ width: '100%' }}>
              <PivotTableUX
                renderers={Object.assign({}, TableRenderers)}
                data={dataSource}
                onChange={(s) => {
                  setSettings(s)
                  setHash(getHashTable(s))
                  setShowTableqp(true)
                }}
                vals={settings.default_value ? [settings.default_value] : ['amount']}
                cols={['Fecha', 'Proyecciones']}
                rows={['Linea de reporte']}
                aggregatorName={settings.default_format || formatBaseNumber}
                hiddenFromDragDrop={['amount', 'price', 'quantity']}
                hiddenFromAggregators={
                  dataSource[0] &&
                  dataSource[0].filter((el) => !['amount', 'price', 'quantity'].includes(el))
                }
                tableOptions={{
                  linesOrder: linesOrder,
                  linesTotal: linesTotal,
                  linesRatio: linesRatio,
                  linesRatioN: linesRatioN,
                  referenceBase: valueReferenceBase,
                }}
                tableFilter={{}}
                plotlyConfig={{
                  responsive: true,
                }}
                {...settings}
              />
            </div>
          </Dropdown>
        </Spin>
      )
    }

    return (
      <ComparativeTable
        loading={loading}
        dataSource={rows1}
        periodTableColumns={periodTableColumns}
        setPeriodTableColumns={setPeriodTableColumns}
        periodTableSettings={periodTableSettings}
        filters={selectedFilters}
        selectedDates={selectedDates}
        tableTypeKey={keyComparisonTable}
        referenceBase={referenceBase}
        referenceComparison={referenceComparison}
        currencyData={currencyData}
      />
    )
  }

  const renderCanSave = () => {
    const { data, ...rest } = settings
    return !_.isEmpty(rest) || (!_.isEmpty(rest) && !_.isEqual(defaultData, defaultSettings))
  }

  const handleEditName = (item) => {
    createLayout('reports', `${periodId}-${reportId}-coparations`, item.name, {
      ...item,
      name: newName,
    })
      .then(() =>
        fetchLayoutList('reports', `${periodId}-${reportId}-coparations`).then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setShowEdit(false)
          setRowId(false)
        }),
      )
      .catch((error) => {
        setShowEdit(false)
        setRowId(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const params = {
    step: null,
    type_table: 'report',
    report_id: reportId,
  }

  const onDelete = (item, { ...props }) => {
    handleDelete({
      setIsLoading: setIsSaving,
      deleteLayout: () => deleteLayout('reports', `${periodId}-${reportId}-coparations`, item.name),
      onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
      onClose: () => setIsSaving(false),
      ...props,
    })
  }

  const handleClickBase = (key) => {
    setReferenceBase_(key)
    window.history.replaceState(
      null,
      '',
      `/analisis/${periodId}/reportesplika/${reportId}/${type}/${currencyData?.id}/${tabKey}/${
        key.id ? `${key.value}-${key.id}` : key.value
      }`,
    )
  }

  const getAlreadyExist = () => {
    if (table) {
      const dataHash = !isEqual(hash, table) && hash !== null ? hash : table
      const json = Buffer.from(dataHash, 'base64').toString()
      const dataTable = JSON.parse(json)
      const { columns, cols, aggregatorName, rows, default_value, vals, default_format } =
        dataTable.config
      const data = layoutList.values.find((el) => {
        const { column_order, row_order, ...restProps } = el.config
        const dataConfig = {
          ...restProps,
          rows: getRowAuxName(restProps.rows, allDimensionsList),
          columns: getColsAuxName(restProps.columns, allDimensionsList),
        }
        return _.isEqual(
          {
            columns: columns ? columns : cols,
            rows,
            default_value: default_value ? default_value : vals[0],
            default_format: default_format ? default_format : DEFAULT_FORMAT_CREATE[aggregatorName],
          },
          dataConfig,
        )
      })

      if (data) {
        const { column_order, row_order, ...restProps } = data.config

        const filterData = {
          ...restProps,
          rows: getRowAuxName(restProps.rows, allDimensionsList),
          columns: getColsAuxName(restProps.columns, allDimensionsList),
        }

        return _.isEqual(
          {
            columns: columns ? columns : cols,
            rows,
            default_value: default_value ? default_value : vals[0],
            default_format: default_format ? default_format : DEFAULT_FORMAT_CREATE[aggregatorName],
          },
          filterData,
        )
      } else {
        return false
      }
    } else {
      return _.isEqual(defaultData, defaultSettings)
    }
  }

  return (
    <Row gutter={[16, 16]}>
      {!enabledComparativeTable && (
        <Col span={24}>
          <ColumnsAndRowsSelections
            onRefresh={onRefetch}
            params={params}
            period_id={periodId}
            setPagination={setTablePagination}
          />
        </Col>
      )}
      <Col span={24}>
        {!enabledComparativeTable && (
          <ComparativeSwitch
            tableTypeKey={keyComparisonTable}
            onChange={(value) => setKeyComparisonTable(value.target.value)}
          />
        )}
      </Col>
      <Col span={24}>
        <Space direction="horizontal">
          <SelectType.EvolutionSelect
            loading={loading}
            groupProjection={projectionsSelect}
            activeOptionTable={getReferenceBase()}
            setActiveByPeriodTable={handleClickBase}
            tabKey={tabKey}
          />
          <SelectType.ComparisonSelect
            loading={loading}
            groupProjection={projectionsSelect}
            baseReference={getReferenceBase()}
            selectedOptions={localReferenceComparison}
            setSelectedOptions={setReferenceComparison_}
          />
          <Button.Primary
            onClick={() => handleComparisonSelection(localReferenceComparison)}
            title="ACTION_APPLY"
          />
        </Space>
      </Col>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
        {showProgressBar && <ProgressBar isLoading={isProgressLoading} />}
      </Col>
      <Col span={24}>
        <TableActions
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          onClick={() =>
            downloadComparativeXLS(
              reportId,
              paramsDownload,
              t('REPORT_COMPARISON_FILE_NAME', {
                periodName: selectedPeriod.name,
                date: moment().format('lll'),
              }),
            )
          }
          onClickDownloadSpreadsheets={() => setShowModal(true)}
          onClickSaveFav={() => setShowPopover(true)}
          visibleFav={showPopover}
          onClickSave={() =>
            handleSave({
              setIsLoading: setIsSaving,
              createLayout: () => onCreate(),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onRefresh: onRefetchComparative,
              onClose,
            })
          }
          onClickCancelSaveFav={onClose}
          setFavouriteName={setFavouriteName}
          favouriteName={favouriteName}
          isLoading={isSaving}
          hasFavourite={layoutList.values.length > 0}
          options={layoutList.values.map((el) => {
            return { name: el.name, id: el.name, primary: el.primary }
          })}
          canSave={renderCanSave()}
          viewSelect={viewSelect}
          setViewSelect={(val) => {
            const aux = layoutList.values.find((el) => el.name === val)
            setViewSelect(aux)
          }}
          alreadyExist={getAlreadyExist()}
          layoutLoading={isLayoutLoading}
          setPrimary={(data) =>
            handleSavePrimary({
              setIsLoading: setIsSaving,
              createLayout: () => onSavePrimary(data),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onClose: () => setIsSaving(false),
            })
          }
          onClickDeleteFav={(name) =>
            handleDelete({
              setIsLoading: setIsSaving,
              deleteLayout: () =>
                deleteLayout('reports', `${periodId}-${reportId}-coparations`, name),
              onRefetch: () => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
              onClose: () => setIsSaving(false),
            })
          }
          showComments={
            viewComment ||
            dataComments.filter((el) => el.key === `${commentKey}-table-comparison` && !el.resolved)
              .length > 0
          }
          hasComment={hasComment}
          setHasComment={setHasComment}
          setViewComment={setViewComment}
          userDetail={loggedUser}
          settings={settings}
          setDataComments={setDataComments}
          dataComments={dataComments.filter(
            (el) => (el.key === `${commentKey}-table-comparison` && !el.resolved) || !el.text,
          )}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          removeNewComment={removeNewComment}
          currencyData={currencyData}
          onClickEditNameFav={() => setShowModalEdit(true)}
        />
      </Col>
      <Col span={24}>
        {/* TODO: NEW FOR REVAMP TABLE */}
        {renderTable()}
      </Col>
      <Col span={24}>
        {!isLoadingChart && !isProgressLoading && (
          <ReportChart
            rows={
              !_.isEmpty(dataComparativeChartDefault.type_data)
                ? dataComparativeChartDefault.type_data[
                    _.keys(dataComparativeChartDefault.type_data)[0]
                  ].data
                : []
            }
            lineId={dataComparativeChartDefault.lineId}
            selectedDates={selectedDates}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            commentKey={commentKey}
            currencyData={currencyData}
            dataChart={dataComparativeChart}
            props={{ margin: { right: 190 } }}
          />
        )}
      </Col>
      <FavouriteEditModal
        visible={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        dataSource={layoutList.values}
        viewSelect={viewSelect}
        setIsSaving={setIsSaving}
        onSavePrimary={(item) => onSavePrimary(item)}
        onRefetch={() => fetchLayoutList('reports', `${periodId}-${reportId}-coparations`)}
        handleDelete={(item) => {
          if (layoutList.values.length > 1 && item.primary) {
            const filterItems = layoutList.values.filter((el) => el.name !== item.name)
            const newItem = filterItems[filterItems.length - 1]
            onDelete(item, {
              onSetPrimary: () =>
                createLayout('reports', `${periodId}-${reportId}-coparations`, newItem.name, {
                  ...newItem,
                  primary: true,
                }),
              setViewSelect: () => setViewSelect(newItem),
            })
          } else if (layoutList.values.length === 1) {
            onDelete(item, {
              onRefetch: () =>
                Promise.all([
                  fetchLayoutList('reports', `${periodId}-${reportId}-coparations`),
                  onRefetchComparative(),
                ]),
              setSettings: () =>
                setSettings({
                  ...settings,
                  vals: ['amount'],
                  cols: ['Fecha', 'Proyecciones'],
                  rows: ['Linea de reporte'],
                  aggregatorName: 'Suma (Decimales)',
                }),
            })
          } else {
            onDelete(item, {})
          }
        }}
        handleEditName={(item) => handleEditName(item)}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        rowId={rowId}
        setRowId={setRowId}
        newName={newName}
        setNewName={setNewName}
      />
      <DownloadModal
        title={INTEGRATIONS.KEYS.SPREADSHEETS_KEY}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={loggedUser.folder_id}
        dataSpreadsheets={{
          type_load: 'report_comparative',
          params: {
            ...paramsDownload,
            report_id: reportId,
          },
        }}
      />
    </Row>
  )
}

const mapStateToProps = (state) => ({
  dataComparative: reports.selectors.getReportComparativeDetail(state),
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  dataComparativeByReport: reports.selectors.getDataComparativeByReport(state),
  layoutList: control.selectors.getLayoutList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  hasComment: configuration.selectors.getHasComment(state),
  loggedUser: login.selectors.getWhoAmI(state),
  dataComparativeChart: reports.selectors.getDataComparativeChart(state),
  dataComparativeChartDefault: reports.selectors.getDataComparativeChartDefault(state),
})

const mapDispatchToProps = {
  downloadComparativeXLS: reports.actions.downloadComparativeXLS,
  createLayout: control.actions.createLayout,
  fetchLayoutList: control.actions.fetchLayoutList,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  deleteLayout: control.actions.deleteLayout,
  setHasComment: configuration.actions.setHasComment,
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
  fetchDataComparativeChart: reports.actions.fetchDataComparativeChart,
}

export default connect(mapStateToProps, mapDispatchToProps)(Comparative)
