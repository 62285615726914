import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Tabs, Spin } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import { PeriodNavigator } from 'modules/core/components'
import AdjustmentModule from '../../../adjustments/containers/AdjustmentsList/components/AdjustmentModule'
import { TracingList } from './components'
import { isUserAllowed, getOperationsByKeys, sendUserDataToGTM } from 'modules/core/utils'
import { ADJUSTMENTS, PROJECTION, ROLES } from 'modules/core/constants'
import { useParams } from 'react-router-dom'
import { AdjustmentsList } from 'modules/adjustments/containers'
import control from 'modules/control'
import login from 'modules/login'
import DistributionModule from './components/DistributionModule'
import AnalysisModule from './components/AnalysisModule'

const { CONTROL__REAL, CONTROL__ADJUSTMENTS } = ROLES

const ControlList = ({ fetchControlList, controlList, loggedUser }) => {
  // ab_adjustment
  const ab_adjustment = useFeatureIsOn('ab_adjustment')
  const ab_distribution = useFeatureIsOn('ab_distribution')
  const ab_followUp_analysis = useFeatureIsOn('ab_followup_analysis')
  let { periodId, type } = useParams()
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [dimensionId, setDimensionId] = useState(null)
  const [tabKey, setTabKey] = useState(type || PROJECTION.REAL)
  const { t } = useTranslation()

  useEffect(() => {
    setIsDataLoading(true)
    fetchControlList({
      period_id: periodId,
      ...(dimensionId && { dimension_id: dimensionId }),
    }).then(() => {
      setIsScreenLoading(false)
      setIsDataLoading(false)
      getTabKey()
    })
  }, [fetchControlList, periodId, dimensionId])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/seguimiento')
  }, [loggedUser])

  const canUserAccessReal = isUserAllowed(getOperationsByKeys([CONTROL__REAL]))
  const canUserAccessAdjustments = isUserAllowed(getOperationsByKeys([CONTROL__ADJUSTMENTS]))

  const showDefaultPanelKey = () => {
    if (canUserAccessReal) {
      return PROJECTION.REAL
    } else if (canUserAccessAdjustments) {
      return ADJUSTMENTS.KEY.ADJUSTMENT_KEY
    }
  }

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_CONTROL') }]} />

  const getTabKey = () => {
    if (type) {
      setTabKey(type)
    } else {
      setTabKey(showDefaultPanelKey())
    }
  }

  const handleClick = (key) => {
    setTabKey(key)
    window.history.replaceState(null, '', `/seguimiento/${periodId}/${key}`)
  }

  return (
    <SiderLayout>
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && (
        <>
          {renderHeader()}
          <Tabs defaultActiveKey={tabKey} onChange={(key) => handleClick(key)} size="small">
            {canUserAccessReal && (
              <Tabs.TabPane tab={t('LABEL_REAL')} key="real">
                {controlList.count > 0 && (
                  <TracingList
                    dataSource={controlList}
                    onFetchProjections={() =>
                      fetchControlList({
                        period_id: periodId,
                        ...(dimensionId && { dimension_id: dimensionId }),
                      })
                    }
                    setDimensionId={setDimensionId}
                    isDataLoading={isDataLoading}
                  />
                )}
              </Tabs.TabPane>
            )}
            {canUserAccessAdjustments && (
              <Tabs.TabPane tab={t('ADJUSTMENT_MANAGMENT_LABEL')} key="ajustes">
                {ab_adjustment ? <AdjustmentModule /> : <AdjustmentsList />}
              </Tabs.TabPane>
            )}
            {ab_distribution && canUserAccessAdjustments && (
              <Tabs.TabPane tab={t('DISTRIBUTION_MANAGMENT_LABEL')} key="distribuciones">
                <DistributionModule />
              </Tabs.TabPane>
            )}
            {ab_followUp_analysis && (
              <Tabs.TabPane tab={'Análisis'} key="analysis">
                <AnalysisModule period={periodId} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  controlList: control.selectors.getControlList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchControlList: control.actions.fetchControlList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlList)
