import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
import { connect } from 'react-redux'
import { usePagination } from 'modules/core/customHooks'
import { useTranslation } from 'react-i18next'
import { DropdownMenu, ImportDataModal } from 'modules/core/components'
import { CloseCircleOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons'
import home from 'modules/home'

const StatusConnection = ({ fetchStatusConnection, statusConnection, dataConnectors }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [connectorId, setConnectorId] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    setIsLoading(true)
    fetchStatusConnection({ ...tablePagination }).then(() => setIsLoading(false))
  }, [fetchStatusConnection, tablePagination])

  const columns = [
    {
      title: t('FIELD_CONNECTOR'),
      dataIndex: 'connector',
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'active',
      render: (active) =>
        active ? (
          <Tag color="success" icon={<CheckCircleOutlined />}>
            {t('HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_SUCCESS')}
          </Tag>
        ) : (
          <Tag color="error" icon={<CloseCircleOutlined />}>
            {t('HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_FAIL')}
          </Tag>
        ),
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id, row) => {
        return (
          !row.active && (
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={[
                {
                  title: t('HOME_INITIAL_STEPS_EDIT_CREDENTIALS_ACTION'),
                  icon: <EditOutlined />,
                  onClick: () => {
                    setConnectorId(row.connector.toLowerCase())
                    setShowConnectModal(true)
                  },
                },
              ]}
            />
          )
        )
      },
    },
  ]

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={statusConnection}
        onChange={setTablePagination}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: statusConnection?.length || 0,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
      />
      {connectorId && (
        <ImportDataModal
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          connectorData={dataConnectors.find((el) => el.id === connectorId)}
          onRefetch={() => fetchStatusConnection({ ...tablePagination })}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  statusConnection: home.selectors.getStatusConnection(state),
  dataConnectors: home.selectors.getDataConnectors(state),
})

const mapDispatchToProps = {
  fetchStatusConnection: home.actions.fetchStatusConnection,
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusConnection)
