import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import { PeriodNavigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { useTranslation } from 'react-i18next'
import { ReportDashboards, ReportList } from './components'
import { sendUserDataToGTM } from 'modules/core/utils'
import { useParams } from 'react-router-dom'
import login from 'modules/login'

import './ReportHome.scss'

const ReportHome = ({ loggedUser }) => {
  let { periodId, type } = useParams()
  const [tabKey, setTabKey] = useState(type || 'reportesplika')
  const { t } = useTranslation()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `?tab=${tabKey}` : ''

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/analisis')
  }, [loggedUser])

  const renderHeader = () => <PeriodNavigator items={[{ name: t('LABEL_ANALYSIS') }]} />

  const handleClick = (key) => {
    setTabKey(key)
    window.history.replaceState(null, '', `/analisis/${periodId}/${key}`)
  }

  return (
    <SiderLayout extraKey={key}>
      {renderHeader()}
      <Tabs size="small" onChange={(key) => handleClick(key)} defaultActiveKey={tabKey}>
        <Tabs.TabPane tab={t('REPORT_PLIKA_CARD_TITLE')} key={'reportesplika'}>
          <ReportList />
        </Tabs.TabPane>
        {loggedUser.quicksight && (
          <Tabs.TabPane tab={t('REPORT_SELF_SERVICE_FIELD')} key={'reportesselfservice'}>
            <ReportDashboards />
          </Tabs.TabPane>
        )}
      </Tabs>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(ReportHome)
